import React from 'react';
import s from "../styles/footer.module.css";
import logoFooter from "../assets/img/logoFooter.png";
import { Link } from 'react-router-dom';


const Footer = () => {
    return (
        <div className={s.footer_container}>
            <div className={s.footerWraper}>

                <div className={s.logoNavWraper}>
            <img src={logoFooter} alt="logoFooter"/>
            <ul className={s.navFooterLink}>
                <li>
                    <Link to="feedback" style={{ textDecoration: "none", color: "#fff" }}>
                        Feedback
                    </Link>
                </li>
                <li>
                    <Link to="delivery" style={{textDecoration: "none", color: "#fff" }}>
                        Delivery
                    </Link>
                </li>
                <li>
                    <Link to="payment" style={{textDecoration: "none", color: "#fff" }}>
                        Payment
                    </Link>
                </li>
                <li>
                    <Link to="contacts" style={{textDecoration: "none", color: "#fff" }}>
                        Contacts
                    </Link>
                </li>
            </ul>

            </div>

            <ul className={s.footerContacts}>
                <li className={s.footerPhone}>
                    <span className={s.phoneNumber}>+3 (888) 00-777-00</span>
                </li>
                <li className={s.footerEmail}>
                    <span className={s.emailaddress}>delivery@midas.rest</span>
                </li>
            </ul>
            </div> 
            <div className={s.footerLine}></div>
            <div style={{display: "flex", justifyContent: "space-between", paddingBottom: "10px"}}>
                <p className={s.copyRights}>© 2009–2019 «MIDAS» All Rights Reserved</p>
                <ul className={s.privacy}>
                <li>
                    Privacy Policy
                </li>
                <li>
                    Terms of Service 
                </li>
                </ul>
            </div>
        </div>
    );
}

export default Footer;
