import React from "react";
import s from "../styles/popular_food.module.css";
import Orders from "./../utils/orderedOften";
import cart_img from "../assets/img/cart_img.png";
import { Link } from "react-router-dom";
import { useCart } from "../utils/context";

const OrderedOften = () => {
  // use context
  const { addToCart } = useCart();

  return (
    <div>
      <div className={s.popularOrders}>
        <div className={s.popular__container}>
          <div className={s.popular__ordertitle}>
            With this dish, <br /> people often order:
          </div>
          <div className={s.popular__cards}>
            {Orders.map((order) => (
              <div className={s.card}>
                <Link
                  to={`/popular-food/${order.id}`}
                  className={s.card}
                  key={order.id}
                >
                  <div className={s.card__img}>
                    <img className={s.card__image} src={order.photo} alt="" />
                  </div>
                </Link>
                <div className={s.card__content}>
                  <div className={s.card__title}>{order.title}</div>
                  <div className={s.card__weight}>
                    <p>{order.weight}</p>
                  </div>
                  <div className={s.card__description}>{order.description}</div>
                  <div className={s.card__buy}>
                    <div className={s.card__price}>{order.price}</div>
                    <img
                      src={cart_img}
                      alt="cart_img"
                      // add to cart
                      onClick={() => addToCart(order)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderedOften;
