import React from 'react';
import s from "../styles/shippingDetails.module.css";
import i from "../styles/shippingDetails.module.css";

const ShippingDetailsForm2 = () => {
    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between", gap: "30px", marginTop: "45px" }}>
        {/* <div className={i.leftBlock2}>
          <div style={{display: "flex"}}>
            <span className={i.noContactDelivery}>Contactless delivery</span>
            <span className={i.noContactDeliveryPrice}>+0 $</span>
          </div>
          <p className={i.textDelivery}>
          Delivery is available daily from 12:00 PM to 12:00 AM, with a time window of 1 to 1.5 hours.
          </p>
        </div> */}
        {/* <div className={s.rightBlock2}>
        <div style={{display: "flex"}}>
            <span className={s.noContactDelivery}>Self-pickup</span>
            <span className={s.noContactDeliveryPrice}>+0 $</span>
          </div>
          <p className={s.textDelivery}>
          Available from 12:00 PM to 12:00 AM at the address: 77, Shevchenko str., Kharkiv.
          </p>
        </div> */}
      </div>
      <form className={s.shippingFormFirstOption} action="">
        <div className={s.order_box} style={{display: "flex",marginTop: "15px"}}>
        <label className={s.coments} htmlFor="textarea">Order <br /> comment</label>
        <textarea></textarea>
        </div>
        <div className={s.wrapperContinueShipping}>
            <button className={s.continueShipping}>continue</button>
            <span className={s.remainShipping}>Last step remaining</span>
            </div> 
      </form>
        </div>
    );
}

export default ShippingDetailsForm2;
