const Soups = [
    {
        id: 1,
        photo: "https://georgianjournal.ge/media/images/georgianews/2015/December/Cuisine/kharcho%20georgian%20beef-walnut%20soup%20with%20fresh%20herbs.jpeg",
        title: "Georgian kharcho",
        weight: "350 g",
        description:
          "The key ingredients: beef, lamb, chicken, rice, blend of seasonings like garlic, coriander.",
        price: "5 $",
        toppings: [
          {
            name: "Garlic sauce",
            price: 1,
          },
          {
            name: "Cilantro and parsley",
            price: 1,
          },
          {
            name: "Lavash",
            price: 1,
          },
        ]
      },
      {
        id: 2,
        photo: "https://1000worldrecipes.com/images/recipes/georgian-vegetable-soup/b601db81.jpg",
        title: "Georgian vegetable soup",
        weight: "430 g",
        description:
          "Key ingredients: tomatoes, potatoes, bell peppers, carrots, onions, and garlic.",
        price: "5 $",
        toppings: [
          {
            name: "Lemon wedges",
            price: 1,
          },
          {
            name: "Cilantro and parsley",
            price: 1,
          },
          {
            name: "Lavash",
            price: 1,
          },
        ]
      },
      {
        id: 3,
        photo: "https://i0.wp.com/www.cookingislifestyle.com/wp-content/uploads/2020/09/Georgian-tomato-soup-with-fried-eggplant.jpg?fit=1181%2C787&ssl=1",
        title: "Georgian tomato soup",
        weight: "430 g",
        description:
          "Key ingredients: tomatoes, potatoes, bell peppers, carrots, onions, and garlic.",
        price: "5 $",
        toppings: [
          {
            name: "Sour cream",
            price: 1,
          },
          {
            name: "Cilantro and parsley",
            price: 1,
          },
          {
            name: "Lavash",
            price: 1,
          },
        ]
      },
      {
        id: 4,
        photo: "https://1000worldrecipes.com/images/recipes/georgian-bean-soup-with-vegetables-and-rice/bfc3e1c4.jpg",
        title: "Georgian bean soup",
        weight: "350 g",
        description:
          "Key ingredients: tomatoes, potatoes, bell peppers, carrots, onions, and garlic.",
        price: "5 $",
        toppings: [
          {
            name: "Sour cream",
            price: 1,
          },
          {
            name: "Cilantro and parsley",
            price: 1,
          },
          {
            name: "Lavash",
            price: 1,
          },
        ]
      },
      {
        id: 5,
        photo: "https://www.vkusnyblog.com/wp-content/uploads/2020/11/chashushuli.jpg",
        title: "Chashushuli",
        weight: "430 g",
        description:
        "Key ingredients: tomatoes, potatoes, bell peppers, carrots, onions, and garlic.",
        price: "5 $",
        toppings: [
          {
            name: "Lemon wedges",
            price: 1,
          },
          {
            name: "Cilantro and parsley",
            price: 1,
          },
          {
            name: "Lavash",
            price: 1,
          },
        ],
      },
      {
        id: 6,
        photo: "https://media-cdn.tripadvisor.com/media/photo-s/13/26/c6/9c/veal-stewed-in-white.jpg",
        title: "Jonjoli",
        weight: "430 g",
        description:
        "Key ingredients: tomatoes, potatoes, bell peppers, carrots, onions, and garlic.",
        price: "5 $",
        toppings: [
          {
            name: "Sour cream",
            price: 1,
          },
          {
            name: "Cilantro and parsley",
            price: 1,
          },
          {
            name: "Lavash",
            price: 1,
          },
        ],
      },
]
export default Soups;