import React from 'react';
import PopularFood from '../components/Popular_desserts';

const Desserts = () => {
    return (
        <div>
            <main>
            <PopularFood/>
            </main> 
        </div>
    );
}

export default Desserts;
