import React, { useState } from 'react';
import s from "../styles/orderPlacement.module.css";

const ContactdetailsForm = (props) => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '+380',
    email: '',
    number: '',
  });


  const [errors, setErrors] = useState({});
  const [formValid, setFormValid] = useState(false)

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }
    if (!formData.phone.trim()) {
      newErrors.phone = "Phone is required";
    }
    if (!isValidEmail(formData.email)) {
      newErrors.email = "Invalid email address";
    }
    if (!isValidNumber(formData.number)) {
      newErrors.number = "Invalid number";
    }
    if(!isValidPhone(formData.phone)) {
      newErrors.phone = "Invalid phone"
    }

    setErrors(newErrors);
    const isValid = Object.keys(newErrors).length === 0;
    setFormData(isValid)
    return isValid
  };

  const isValidEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const isValidNumber = (number) => {
    const numberPattern = /^[0-9]+$/;
    return numberPattern.test(number);
  };

  const isValidPhone = (phone) => {
    const phonePattern = /^\+380\d{0,9}$/;;
    return phonePattern.test(phone);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      alert("Form has been sent successfully");
      props.toggleContactDetails()
    } else {
      alert("Form hasn't been sent successfully");
    }
    setFormData({
      name: '',
      phone: '+380',
      email: '',
      number: '',
  })
}

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
    setErrors({ ...errors, [id]: "" });
  };

  return (
    <div>
      <div>
      <span className={s.question}>Have you already bought from us?</span>
      <span className={s.sugestion}>
        Log in to your personal account, and all your information will be filled
        in automatically
      </span>
      <form onSubmit={handleSubmit} id="contact_form" className={s.delivery} action="">
        <div className={s.box_name}>
          <label htmlFor="name">Name <span style={{}}>*</span></label>
          <input type="text" id="name" value={formData.name} onChange={handleChange}/>
          {errors.name && <p className={s.check}>{errors.name}</p>}
        </div>
        <div className={s.box_phone}>
          <label htmlFor="phone">Phone <span style={{color: "#FBD13E"}}>*</span></label>
          <input type="tel" id="phone" value={formData.phone} onChange={handleChange}/>
          {errors.phone && <p style={{color: "red"}}>{errors.phone}</p>}
        </div>   
        <div className={s.box_email}>
          <label htmlFor="email">email</label>
          <input type="email" id="email"  value={formData.email} onChange={handleChange}/>
          {errors.email && <p className={s.checkEmail}>{errors.email}</p>}
        </div>
        <div className={s.box_number}>
          <label htmlFor="number">number of guests</label>
          <input className={s.number} type="text" id="number" value={formData.number} onChange={handleChange}/>
          {errors.number && <p className={s.checkNumber}>{errors.number}</p>}
        </div>
        <div className={s.wrapperContinue}>
          <button className={s.continue}>continue</button>
          <span className={s.remain}>2 steps remaining</span>
        </div> 
      </form>
      </div>
      
    </div>
  );
}

export default ContactdetailsForm;
