import React from "react";
import s from "../styles/popular_food.module.css";
import Populars from "./../utils/popular_food";
import cart_img from "../assets/img/cart_img.png";
import { Link } from "react-router-dom";
import { useCart } from "../utils/context";

const PopularFood = () => {

  // use context
const {addToCart} = useCart();

  return (
    <div>
      <div className={s.popular}>
        <div className={s.popular__container}>
          <div className={s.popular__title}>Popular dishes</div>
          <div className={s.popular__cards}>
            {Populars.map((popular) => (
              <div className={s.card}>
              <Link to={`/popular-food/${popular.id}`} className={s.card} key={popular.id}>
                <div className={s.card__img}>
                  <img className={s.card__image} src={popular.photo} alt="" />
                </div>
                </Link>
                <div className={s.card__content}>
                  <div className={s.card__title}>{popular.title}</div>
                  <div className={s.card__weight}>
                    <p>{popular.weight}</p>
                  </div>
                  <div className={s.card__description}>
                    {popular.description}
                  </div>
                  <div className={s.card__buy}>
                    <div className={s.card__price}>{popular.price}</div>
                    <img src={cart_img} alt="cart_img" 
                    // add to cart
                    onClick={() =>addToCart(popular)}/>
                  </div>
                </div>
                </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopularFood;
