import React from "react";
import main_logo from "../assets/img/main_logo.png";
import s from "../styles/home.module.css";
import dots from "../assets/img/dots.svg";
import big_union from "../assets/img/big_union.png";
import cart_img from "../assets/img/cart_img.png";
import Populars from "../components/Popular_food";
import Swipper from "../components/Swipper";
import Tiramisu from "../components/Tiramisu";

const Home = () => {
  return (
    <div className={s.home}>
      <main>
        <div className={s.delivery_box}>
          <div className={s.delivery_box_container}>
            <p className={s.food_delivery}>Food delivery from farm products!</p>
            <img className={s.dots} src={dots} alt="dots" />
            <p className={s.phone}>+3 (888) 00-777-00</p>
            <p className={s.email_info}>delivery@midas.rest</p>
            <div className={s.tiramisuBlock}>
            <Tiramisu />
            </div>
            
          </div>
          <div>
            <img className={s.main_logo} src={main_logo} alt="main_logo" />

            <div className={s.red_container}>
              <div className={s.red_container_wrapper}>
                <span className={s.title}>
                  Salmon steak <br /> with vegetables
                </span>
                <span className={s.weight}>
                  <span className={s.number}>250 g</span>
                </span>
                <p>
                  Tender wild salmon steak infused with the juice and aroma of
                  lightly sautéed farm-fresh vegetables.
                </p>
                <div className={s.product_price}>
                  <span className={s.price}>5 $</span>
                  <img src={cart_img} alt="cart_img" />
                </div>
              </div>
            </div>

            <div>
              <img className={s.big_union} src={big_union} alt="big_union" />
            </div>
          </div>
        </div>
      </main>
      <Populars />
      <Swipper />
    </div>
  );
};

export default Home;
