const drinks = [
  {
    title: "155 Belmont",
    photo: "https://www.thecocktaildb.com/images/media/drink/yqvvqs1475667388.jpg",
    description:
      "Try our cocktails, and you will enjoy their magnificent flavors and refreshing aromas!",
    amount: "11 oz",
    price: "8 $",
    id: 37,
    toppings: [
      {
        name: "Double shot of Gin",
        price:3,
      },
      {
        name: "Souvenir cup",
        price: 2,
      },
      {
        name: "Story of the drink",
        price: 1,
      },
    ] 
  },
  {
    title: "57 Chevy",
    photo: "https://www.thecocktaildb.com/images/media/drink/qyyvtu1468878544.jpg",
    description:
      "Try our cocktails, and you will enjoy their magnificent flavors and refreshing aromas!",
    amount: "8 oz",
    price: "8 $",
    id: 38,
    toppings: [
      {
        name: "Double shot of Vodka",
        price:3,
      },
      {
        name: "Souvenir cup",
        price: 2,
      },
      {
        name: "Story of the drink",
        price: 1,
      },
    ] 
  },
  {
    title: "747 Drink",
    photo: "https://www.thecocktaildb.com/images/media/drink/i9suxb1582474926.jpg",
    description:
      "Try our cocktails, and you will enjoy their magnificent flavors and refreshing aromas!",
    amount: "8 oz",
    price: "8 $",
    id: 39,
    toppings: [
      {
        name: "Double shot of Vodka",
        price:3,
      },
      {
        name: "Souvenir cup",
        price: 2,
      },
      {
        name: "Story of the drink",
        price: 1,
      },
    ] 
  },
  {
    title: "9 1/2 Weeks",
    photo: "https://www.thecocktaildb.com/images/media/drink/xvwusr1472669302.jpg",
    description:
      "Try our cocktails, and you will enjoy their magnificent flavors and refreshing aromas!",
    amount: "5 oz",
    price: "8 $",
    id: 40,
    toppings: [
      {
        name: "Double shot of Vodka",
        price:3,
      },
      {
        name: "Souvenir cup",
        price: 2,
      },
      {
        name: "Story of the drink",
        price: 1,
      },
    ] 
  },
  {
    title: "A Gilligan's Island",
    photo: "https://www.thecocktaildb.com/images/media/drink/wysqut1461867176.jpg",
    description:
      "Try our cocktails, and you will enjoy their magnificent flavors and refreshing aromas!",
    amount: "7 oz",
    price: "8 $",
    id: 41,
    toppings: [
      {
        name: "Double shot of Vodka",
        price:3,
      },
      {
        name: "Souvenir cup",
        price: 2,
      },
      {
        name: "Story of the drink",
        price: 1,
      },
    ] 
  },
  {
    title: "A True Amaretto Sour",
    photo: "https://www.thecocktaildb.com/images/media/drink/rptuxy1472669372.jpg",
    description:
      "Try our cocktails, and you will enjoy their magnificent flavors and refreshing aromas!",
    amount: "8 oz",
    price: "8 $",
    id: 42,
    toppings: [
      {
        name: "Double shot of Amaretto",
        price:3,
      },
      {
        name: "Souvenir cup",
        price: 2,
      },
      {
        name: "Story of the drink",
        price: 1,
      },
    ] 
  },
  {
    title: "After Dinner Mint",
    photo: "https://www.thecocktaildb.com/images/media/drink/ruxuvp1472669600.jpg",
    description:
      "Try our cocktails, and you will enjoy their magnificent flavors and refreshing aromas!",
    amount: "10 oz",
    price: "8 $",
    id: 43,
    toppings: [
      {
        name: "Double shot of Vodka",
        price:3,
      },
      {
        name: "Souvenir cup",
        price: 2,
      },
      {
        name: "Story of the drink",
        price: 1,
      },
    ] 
  },
  {
    title: "A1",
    photo: "https://www.thecocktaildb.com/images/media/drink/2x8thr1504816928.jpg",
    description:
      "Try our cocktails, and you will enjoy their magnificent flavors and refreshing aromas!",
    amount: "5 oz",
    price: "8 $",
    id: 44,
    toppings: [
      {
        name: "Double shot of Gin",
        price:3,
      },
      {
        name: "Souvenir cup",
        price: 2,
      },
      {
        name: "Story of the drink",
        price: 1,
      },
    ] 
  },
  {
    title: "Abbey Martini",
    photo: "https://www.thecocktaildb.com/images/media/drink/2mcozt1504817403.jpg",
    description:
      "Try our cocktails, and you will enjoy their magnificent flavors and refreshing aromas!",
    amount: "5 oz",
    price: "8 $",
    id: 45,
    toppings: [
      {
        name: "Double shot of Gin",
        price:3,
      },
      {
        name: "Souvenir cup",
        price: 2,
      },
      {
        name: "Story of the drink",
        price: 1,
      },
    ] 
  },
  {
    title: "Absolut Summertime",
    photo: "https://www.thecocktaildb.com/images/media/drink/trpxxs1472669662.jpg",
    description:
      "Try our cocktails, and you will enjoy their magnificent flavors and refreshing aromas!",
    amount: "11 oz",
    price: "8 $",
    id: 46,
    toppings: [
      {
        name: "Double shot of Vodka",
        price:3,
      },
      {
        name: "Souvenir cup",
        price: 2,
      },
      {
        name: "Story of the drink",
        price: 1,
      },
    ] 
  },
  {
    title: "Absolutely Fabulous",
    photo: "https://www.thecocktaildb.com/images/media/drink/abcpwr1504817734.jpg",
    description:
      "Try our cocktails, and you will enjoy their magnificent flavors and refreshing aromas!",
    amount: "5 oz",
    price: "8 $",
    id: 47,
    toppings: [
      {
        name: "Double shot of Vodka",
        price:3,
      },
      {
        name: "Souvenir cup",
        price: 2,
      },
      {
        name: "Story of the drink",
        price: 1,
      },
    ] 
  },
  {
    title: "Absolutly Screwed Up",
    photo: "https://www.thecocktaildb.com/images/media/drink/yvxrwv1472669728.jpg",
    description:
      "Try our cocktails, and you will enjoy their magnificent flavors and refreshing aromas!",
    amount: "10 oz",
    price: "8 $",
    id: 48,
    toppings: [
      {
        name: "Double shot of Vodka",
        price:3,
      },
      {
        name: "Souvenir cup",
        price: 2,
      },
      {
        name: "Story of the drink",
        price: 1,
      },
    ] 
  },
  {
    title: "Ace",
    photo: "https://www.thecocktaildb.com/images/media/drink/l3cd7f1504818306.jpg",
    description:
      "Try our cocktails, and you will enjoy their magnificent flavors and refreshing aromas!",
    amount: "5 oz",
    price: "8 $",
    id: 49,
    toppings: [
      {
        name: "Double shot of Gin",
        price:3,
      },
      {
        name: "Souvenir cup",
        price: 2,
      },
      {
        name: "Story of the drink",
        price: 1,
      },
    ] 
  },
  {
    title: "Adam & Eve",
    photo: "https://www.thecocktaildb.com/images/media/drink/vfeumw1504819077.jpg",
    description:
      "Try our cocktails, and you will enjoy their magnificent flavors and refreshing aromas!",
    amount: "8 oz",
    price: "8 $",
    id: 50,
    toppings: [
      {
        name: "Double shot of Gin",
        price:3,
      },
      {
        name: "Souvenir cup",
        price: 2,
      },
      {
        name: "Story of the drink",
        price: 1,
      },
    ] 
  },
  {
    title: "Addington",
    photo: "https://www.thecocktaildb.com/images/media/drink/ib0b7g1504818925.jpg",
    description:
      "Try our cocktails, and you will enjoy their magnificent flavors and refreshing aromas!",
    amount: "5oz",
    price: "8 $",
    id: 51,
    toppings: [
      {
        name: "Extra vermouth",
        price:3,
      },
      {
        name: "Souvenir cup",
        price: 2,
      },
      {
        name: "Story of the drink",
        price: 1,
      },
    ] 
  },
  {
    title: "Addison",
    photo: "https://www.thecocktaildb.com/images/media/drink/yzva7x1504820300.jpg",
    description:
      "Try our cocktails, and you will enjoy their magnificent flavors and refreshing aromas!",
    amount: "5 oz",
    price: "8 $",
    id: 52,
    toppings: [
      {
        name: "Double shot of Gin",
        price:3,
      },
      {
        name: "Souvenir cup",
        price: 2,
      },
      {
        name: "Story of the drink",
        price: 1,
      },
    ] 
  },
  {
    title: "Addison Special",
    photo: "https://www.thecocktaildb.com/images/media/drink/4vo5651493068493.jpg",
    description:
      "Try our cocktails, and you will enjoy their magnificent flavors and refreshing aromas!",
    amount: "11 oz",
    price: "8 $",
    id: 53,
    toppings: [
      {
        name: "Double shot of Vodka",
        price:3,
      },
      {
        name: "Souvenir cup",
        price: 2,
      },
      {
        name: "Story of the drink",
        price: 1,
      },
    ] 
  },
  {
    title: "Adios Amigos Cocktail",
    photo: "https://www.thecocktaildb.com/images/media/drink/8nk2mp1504819893.jpg",
    description:
      "Try our cocktails, and you will enjoy their magnificent flavors and refreshing aromas!",
    amount: "5 oz",
    price: "8 $",
    id: 54,
    toppings: [
      {
        name: "Double shot of Rum",
        price:3,
      },
      {
        name: "Souvenir cup",
        price: 2,
      },
      {
        name: "Story of the drink",
        price: 1,
      },
    ] 
  },
];

export default drinks;