const desserts = [
    {
      id: 97,
      title: "Tiramisu",
      description: "Italian dessert with mascarpone and coffee",
      price: "8 $" ,
      weight: "170 g",
      ingredients: ["mascarpone", "coffee", "sponge cake", "cocoa"],
      photo: "https://i.lefood.menu/wp-content/uploads/w_images/2021/09/recept-3730-1-1240x826.jpg",
      toppings: [
        {
          name: "Cup of green tea",
          price: 1,
        },
        {
          name: "Cup of black tea",
          price: 1,
        },
        {
          name: "Cup of coffee",
          price: 2,
        },
      ] 
    },
    {
      id: 98,
      title: "Chocolate Fondant",
      description: "Served hot with ice cream",
      price: "9 $" ,
      weight: "230 g",
      ingredients: ["chocolate", "butter", "eggs", "sugar", "ice cream"],
      photo: "https://img.freepik.com/premium-photo/dessert-traditional-chocolate-fondant-with-ice-cream-chocolate-plate-top-view_187166-27724.jpg?w=2000",
      toppings: [
        {
          name: "Cup of green tea",
          price: 1,
        },
        {
          name: "Cup of black tea",
          price: 1,
        },
        {
          name: "Cup of coffee",
          price: 2,
        },
      ] 
    },
    {
      id: 99,
      title: "Raspberry Cheesecake",
      description: "Creamy cheesecake with fresh raspberries",
      price: "7 $" ,
      weight: "210 g",
      ingredients: ["Philadelphia cheese", "raspberries", "cookies", "sugar"],
      photo: "https://img.taste.com.au/Sybwlkfo/w720-h480-cfill-q80/taste/2016/11/raspberry-cheesecake-23872-1.jpeg",
      toppings: [
        {
          name: "Cup of green tea",
          price: 1,
        },
        {
          name: "Cup of black tea",
          price: 1,
        },
        {
          name: "Cup of coffee",
          price: 2,
        },
      ] 
    },
    {
      id: 100,
      title: "Fruit Salad",
      description: "Fresh fruits with honey and nuts",
      price: "5 $" ,
      weight: "220 g",
      ingredients: ["apples", "pears", "oranges", "honey", "nuts"],
      photo: "https://www.californiastrawberries.com/wp-content/uploads/2021/05/Rainbow-Fruit-Salad-1024-500x500.jpg",
      toppings: [
        {
          name: "Cup of green tea",
          price: 1,
        },
        {
          name: "Cup of black tea",
          price: 1,
        },
        {
          name: "Cup of coffee",
          price: 2,
        },
      ] 
    },
    {
      id: 101,
      title: "Panna Cotta",
      description: "Italian dessert with vanilla and strawberries",
      price: "7 $" ,
      weight: "280 g",
      ingredients: ["cream", "vanilla", "strawberries", "gelatin"],
      photo: "https://www.fifteenspatulas.com/wp-content/uploads/2011/08/Panna-Cotta-Fifteen-Spatulas-2.jpg",
      toppings: [
        {
          name: "Cup of green tea",
          price: 1,
        },
        {
          name: "Cup of black tea",
          price: 1,
        },
        {
          name: "Cup of coffee",
          price: 2,
        },
      ] 
    },
    {
      id: 102,
      title: "Carrot Cake",
      description: "With cream cheese and nuts",
      price: "7 $" ,
      weight: "220 g",
      ingredients: ["carrots", "cream cheese", "nuts", "cinnamon"],
      photo: "https://food.fnr.sndimg.com/content/dam/images/food/fullset/2007/2/16/0/ry0401_carrotcake.jpg.rend.hgtvcom.1280.1280.suffix/1552488933139.jpeg",
      toppings: [
        {
          name: "Cup of green tea",
          price: 1,
        },
        {
          name: "Cup of black tea",
          price: 1,
        },
        {
          name: "Cup of coffee",
          price: 2,
        },
      ] 
    },
    {
      id: 103,
      title: "Brownie Ice Cream",
      description: "Brownie with vanilla ice cream",
      price: "9 $" ,
      weight: "210 g",
      ingredients: ["brownie", "ice cream", "chocolate sauce"],
      photo: "https://images-gmi-pmc.edge-generalmills.com/34c0ac81-656b-45f1-b0b9-6eff351f2dc9.jpg",
      toppings: [
        {
          name: "Cup of green tea",
          price: 1,
        },
        {
          name: "Cup of black tea",
          price: 1,
        },
        {
          name: "Cup of coffee",
          price: 2,
        },
      ] 
    },
    {
      id: 104,
      title: "Mango Mousse",
      description: "Light mousse with ripe mango",
      price: "7 $" ,
      weight: "170 g",
      ingredients: ["mango", "cream", "sugar", "gelatin"],
      photo: "https://keralaslive.com/wp-content/uploads/2020/02/Mango-mousse-18.jpg",
      toppings: [
        {
          name: "Cup of green tea",
          price: 1,
        },
        {
          name: "Cup of black tea",
          price: 1,
        },
        {
          name: "Cup of coffee",
          price: 2,
        },
      ] 
    },
    {
      id: 105,
      title: "Truffles",
      description: "Chocolate truffles with filling",
      price: "10 $" ,
      weight: "140 g",
      ingredients: ["chocolate", "cream", "liqueur", "cocoa"],
      photo: "https://carveyourcraving.com/wp-content/uploads/2022/12/easy-chocolate-truffles-recipe-500x500.jpg",
      toppings: [
        {
          name: "Cup of green tea",
          price: 1,
        },
        {
          name: "Cup of black tea",
          price: 1,
        },
        {
          name: "Cup of coffee",
          price: 2,
        },
      ] 
    },
    {
      id: 106,
      title: "Creme Caramel",
      description: "Classic French dessert",
      price: "8 $" ,
      weight: "190 g",
      ingredients: ["eggs", "sugar", "vanilla", "milk"],
      photo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrJF2AcdLR2HvEHT4lF5VXtS769-kLduBtFw&usqp=CAU",
      toppings: [
        {
          name: "Cup of green tea",
          price: 1,
        },
        {
          name: "Cup of black tea",
          price: 1,
        },
        {
          name: "Cup of coffee",
          price: 2,
        },
      ] 
    },
    {
      id: 107,
      title: "Nut Roll",
      description: "Sponge roll with nuts and cream",
      price: "8 $" ,
      weight: "160 g",
      ingredients: ["sponge cake", "nuts", "cream", "sugar"],
      photo: "https://ciaoflorentina.com/wp-content/uploads/2016/12/IMG_7596.jpg",
      toppings: [
        {
          name: "Cup of green tea",
          price: 1,
        },
        {
          name: "Cup of black tea",
          price: 1,
        },
        {
          name: "Cup of coffee",
          price: 2,
        },
      ] 
    },
    {
      id: 108,
      title: "Lemon Tart",
      description: "Tart with lemon curd and meringue",
      price: "8 $" ,
      weight: "140 g",
      ingredients: ["lemons", "eggs", "butter", "sugar"],
      photo: "https://www.sidechef.com/recipe/9cb4d486-34e8-40e4-8630-0656f42d0fed.jpg?d=1408x1120",
      toppings: [
        {
          name: "Cup of green tea",
          price: 1,
        },
        {
          name: "Cup of black tea",
          price: 1,
        },
        {
          name: "Cup of coffee",
          price: 2,
        },
      ] 
    },
    {
      id: 109,
      title: "Chocolate Mousse",
      description: "Delicate chocolate mousse",
      price: "7 $" ,
      weight: "170 g",
      ingredients: ["chocolate", "cream", "eggs", "sugar"],
      photo: "https://juliemarieeats.com/wp-content/uploads/2022/11/Peanut-butter-and-Dark-Chocolate-Mousse-Cake-4-500x500.jpg",
      toppings: [
        {
          name: "Cup of green tea",
          price: 1,
        },
        {
          name: "Cup of black tea",
          price: 1,
        },
        {
          name: "Cup of coffee",
          price: 2,
        },
      ] 
    },
    {
      id: 110,
      title: "Blueberry Cheesecake",
      description: "Cheesecake with blueberry topping",
      price: "7 $" ,
      weight: "190 g",
      ingredients: ["Philadelphia cheese", "blueberry jam", "cookies"],
      photo: "https://www.scrumptiously.com/wp-content/uploads/2023/02/BlueberryCheesecake.webp",
      toppings: [
        {
          name: "Cup of green tea",
          price: 1,
        },
        {
          name: "Cup of black tea",
          price: 1,
        },
        {
          name: "Cup of coffee",
          price: 2,
        },
      ] 
    },
    {
      id: 111,
      title: "Strawberry Parfait",
      description: "Layered dessert with strawberries and vanilla yogurt",
      price: "7 $" ,
      weight: "130 g",
      ingredients: ["strawberries", "vanilla yogurt", "granola"],
      photo: "https://www.bordbia.ie/globalassets/strawberry-amp-yoghurt-parfait.jpg",
      toppings: [
        {
          name: "Cup of green tea",
          price: 1,
        },
        {
          name: "Cup of black tea",
          price: 1,
        },
        {
          name: "Cup of coffee",
          price: 2,
        },
      ] 
    },
    {
      id: 112,
      title: "Chocolate Walnut Cake",
      description: "Cake with walnuts and chocolate ganache",
      price: "9 $" ,
      weight: "230 g",
      ingredients: ["walnuts", "chocolate", "sugar", "butter"],
      photo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTg9rdlDUUSsnTvjJ5nTamNY8Dej3fSjrlUw&usqp=CAU",
      toppings: [
        {
          name: "Cup of green tea",
          price: 1,
        },
        {
          name: "Cup of black tea",
          price: 1,
        },
        {
          name: "Cup of coffee",
          price: 2,
        },
      ] 
    },
    {
      id: 113,
      title: "Raspberry Ice Cream",
      description: "Ice cream with raspberry flavor",
      price: "5 $" ,
      weight: "190 g",
      ingredients: ["raspberry", "milk", "sugar"],
      photo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBQvKvtnYmZQqFPMoYsVCVrK0LcHpSXoG75Q&usqp=CAU",
      toppings: [
        {
          name: "Cup of green tea",
          price: 1,
        },
        {
          name: "Cup of black tea",
          price: 1,
        },
        {
          name: "Cup of coffee",
          price: 2,
        },
      ] 
    },
    {
      id: 114,
      title: "Vanilla Waffles",
      description: "Waffles with vanilla flavor and maple syrup",
      price: "5 $",
      weight: "240 g",
      ingredients: ["waffles", "vanilla", "maple syrup"],
      photo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0XAOdQUC8ADQyZqeRIquXZ98RjBRwmddPQQ&usqp=CAU",
      toppings: [
        {
          name: "Cup of green tea",
          price: 1,
        },
        {
          name: "Cup of black tea",
          price: 1,
        },
        {
          name: "Cup of coffee",
          price: 2,
        },
      ] 
    },
  ];

  export default desserts;
  