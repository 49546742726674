import React, { useEffect, useState } from "react";
import { useCart } from "../utils/context";
import s from "../styles/cartModal.module.css";
import closeLoginIcon from "../assets/img/closeLoginIcon.png";
import removeIcon from "../assets/img/removeIcon.svg";
import minus from "../assets/img/minus.png";
import plus from "../assets/img/plus.png";
import rectangle from "../assets/img/Rectangle 44.png";
import { Link } from "react-router-dom";

const CartModal = () => {
  const { cartItems } = useCart();
  const {
    isModalOpen,
    setIsModalOpen,
    increaseCount,
    reduceCount,
    removeFromCart,
  } = useCart();

  const calculateTotalSum = () => {
    return cartItems.reduce(
      (sum, item) => sum + (item.count> 0 ? parseFloat(item.price) * item.count : 0), 0
    )
  }

  const [totalSum, setTotalSum] = useState(calculateTotalSum())

  console.log(cartItems);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const onEscapeKeyPress = (e) => {
    if (e.key === "Escape") {
      closeModal();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", onEscapeKeyPress);
    return () => {
      window.removeEventListener("keydown", onEscapeKeyPress);
    };
  }, []);

  useEffect(() => {
    setTotalSum(calculateTotalSum())
  }, [cartItems])

  // // Calculate the total sum of ordered items
  // const totalSum = cartItems.reduce(
  //   (sum, item) =>
  //     sum + (item.count > 0 ? parseFloat(item.price) * item.count : 0),
  //   0
  // );

  return (
    <div>
      {isModalOpen && (
        <div className={s.cartContainer}>
          <div className={s.cart}>
            <h2>Shopping cart</h2>
            <button className={s.closeLoginButton} onClick={closeModal}>
              <img src={closeLoginIcon} alt="" />
            </button>

            <table className={s.price_table}>
              <thead>
                <tr className={s.headTitle}>
                  <th className={s.image}></th>
                  <th className={s.nameHead}>Dish:</th>
                  <th className={s.priceHead}>Price:</th>
                  <th className={s.count_box}>Quantity:</th>
                  <th className={s.total_priceHead}>Total Price:</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {cartItems.map((item) => (
                  <tr key={item.id}>
                    <td className={s.image}>
                      <img src={item.photo} alt="" />
                    </td>
                    <td className={s.name}>{item.title}</td>
                    <td className={s.price}>{item.price}</td>
                    <td className={s.count_box}>
                      <img className={s.rectangle} src={rectangle} alt="" />
                      <button
                        style={{ backgroundColor: "black", border: "none" }}
                        onClick={() => reduceCount(item.id)}
                      >
                        <img className={s.minus} src={minus} alt="minus" />
                      </button>
                      <span className={s.count}>{item.count}</span>
                      <button
                        style={{ backgroundColor: "black", border: "none" }}
                        onClick={() => increaseCount(item.id)}
                      >
                        <img className={s.plus} src={plus} alt="plus" />
                      </button>
                    </td>
                    <td className={s.total_price}>
                      {item.count > 0 ? parseFloat(item.price).toFixed() * item.count : 0}{" "}
                      $
                    </td>
                    <td>
                      <div className={s.remove_icon_box}
                      >
                        <img
                          className={s.remove_icon}
                          src={removeIcon}
                          alt=""
                          onClick={() => removeFromCart(item.id)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div>
              <div className={s.buttonWrapper}>
                <button className={s.orderPlacementBtn} onClick={closeModal}>
                  <Link to={"orderPlacement"}>
                    <span>buy now</span>
                  </Link>
                </button>
                <div className={s.totalSum}>
                  <span>Total to pay:</span> {totalSum.toFixed()} $
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartModal;