import React from "react";
import FeedBack from "../components/FeedBack";

const Feedback = () => {
  return (
    <div>
      <main>
        <FeedBack />
      </main>
    </div>
  );
};

export default Feedback;
