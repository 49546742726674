import React, { useState } from "react";
import s from "../styles/orderPlacement.module.css";
import y from "../styles/delivery.module.css";
import ShippingDetailsForm1 from "../components/ShippingDetailsForm1";
import ShippingDetailsForm2 from "../components/ShippingDetailsForm2";
import ContactdetailsForm from "../components/ContactdetailsForm";
import dessertBottom from "../assets/img/dessertBottom.png";
import dessertRight from "../assets/img/dessertRight.png";
import greenMark from '../assets/img/greenMark.png';

const Delivery = () => {
  const [selectedDelivery, setSelectedDelivery] = useState("contactless");
  const [contactDetailsChecked, setContactDetailsChecked] = useState(false);
  const [shippingDetailsChecked, setShippingDetailsChecked] = useState(false);
  
  const handleDelivery = (deliveryOption) => {
    setSelectedDelivery(deliveryOption);
  };

  const toggleContactDetails = () => {
    setContactDetailsChecked(!contactDetailsChecked);
  };

  const toggleShippingDetails = () => {
    setShippingDetailsChecked(!shippingDetailsChecked)
  }

  return (
    <div>
      <main>
        {/* <img className={y.feedbackRight} src={dessertRight} alt="" /> */}
        <h2 className={s.OrderPlacementTitle}>Shipping Details</h2>
        <h3>
            01. Contact details{" "}
            {contactDetailsChecked && (
              <img src={greenMark}/>
            )}
          </h3>
          <div className={s.line}></div>
        <ContactdetailsForm toggleContactDetails={toggleContactDetails}/>
        <h3>02. Shipping details{" "}
            {shippingDetailsChecked && (
              <img src={greenMark}/>
            )}</h3>
          <div className={s.line}></div>
        <div className={y.deliveryOption_block}
        >
          <button style={{background: "none", cursor: "pointer"}}
            onClick={() => handleDelivery("contactless")}
            className={
              selectedDelivery === "contactless"
                ? `${y.deliveryOption} ${y.selected}`
                : y.deliveryOption
            }
          >
            <div style={{ display: "flex" }}>
              <span className={y.noContactDelivery}>Contactless delivery</span>
              <span className={y.noContactDeliveryPrice}>+0 $</span>
            </div>
            <p className={y.textDelivery}>
              Delivery is available daily from 12:00 PM to 12:00 AM, with a time
              window of 1 to 1.5 hours.
            </p>
          </button>
          <button style={{background: "none", cursor: "pointer"}}
            onClick={() => handleDelivery("selfpickup")}
            className={
              selectedDelivery === "selfpickup"
                ? `${y.deliveryOption} ${y.selected}`
                : y.deliveryOption
            }
          >
            <div style={{ display: "flex" }}>
              <span className={y.noContactDelivery}>Self-pickup</span>
              <span className={y.noContactDeliveryPrice2}>+0 $</span>
            </div>
            <p className={y.textDelivery}>
              Available from 12:00 PM to 12:00 AM at the address: 77, Shevchenko
              str., Kharkiv.
            </p>
          </button>
        </div>
        {selectedDelivery === "contactless" ? (
          <ShippingDetailsForm1 toggleShippingDetails={toggleShippingDetails} />
        ) : (
          <ShippingDetailsForm2 />
        )}

        {/* <div className={y.bottomImgContainer}>
          <img className={y.feedbackBottom} src={dessertBottom} alt="" />
        </div> */}
      </main>
    </div>
  );
};

export default Delivery;
