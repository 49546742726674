import React, { useState } from "react";
import s from "../styles/shippingDetails.module.css";
import visa from "../assets/img/visa.png";
import pay from "../assets/img/pay.png";
import mastercard from "../assets/img/mastercard.png";
import gpay from "../assets/img/gpay.png";

const PaymentDetailsForm = (props) => {
  const [showBankCard, setShowBankCard] = useState(false);
  const [cash, setCash] = useState(false);
  const [credit, setCredit] = useState(false);

  const [cardFullName, setCardFullName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardDate, setCardDate] = useState("");
  const [cardCVV, setCardCVV] = useState("");
  const [cardError, setCardError] = useState({});

  const validateCard = () => {
    let isValid = true;
    const errors = {};

    if (cardFullName.trim() === "") {
      errors.cardFullName = "Full Name is required";
      isValid = false;
    }

    if (cardNumber.trim() === "") {
      errors.cardNumber = "Card Number is required";
      isValid = false;
    } else if (!isValidCardNumber(cardNumber)) {
      errors.cardNumber = "Enter a valid 16-digit card number";
      isValid = false;
    }

    if (cardDate.trim() === "") {
      errors.cardDate = "Expiry Date is required";
      isValid = false;
    } else if (!isValidDate(cardDate)) {
      errors.cardDate = "Enter a valid expiry date (MM/YY)";
      isValid = false;
    }

    if (cardCVV.trim() === "") {
      errors.cardCVV = "CVV is required";
      isValid = false;
    } else if (!isValidCVV(cardCVV)) {
      errors.cardCVV = "Enter a valid 3-digit CVV";
      isValid = false;
    }

    setCardError(errors);
    return isValid;
  };

  const handleCardSubmit = (e) => {
    e.preventDefault();
    const isValid = validateCard();
    if (isValid) {
      alert("Form has been sent successfully");
      props.togglePaymentDetails();
    } else {
      alert("Form hasn't been sent successfully");
    }
  };

  const isValidCardNumber = (number) => {
    return /^\d{16}$/.test(number);
  };

  const isValidDate = (date) => {
    return /^\d{4}$/.test(date);
  };
  

  const isValidCVV = (cvv) => {
    return /^\d{3}$/.test(cvv);
  };

  const handleShowBankCard = () => {
    setShowBankCard(!showBankCard);
    setCash(false);
    setCredit(false);
  };

  const handleCash = () => {
    setCash(!cash);
    setShowBankCard(false);
    setCredit(false);
  };

  const handleCredit = () => {
    setCredit(!credit);
    setShowBankCard(false);
    setCash(false);
  };

  return (
    <div>
      <form
        className={s.paymentForm}
        action=""
      >
        <div
          className={showBankCard ? s.active : s.activeBlock}
          onClick={handleShowBankCard}
        >
          <input
            type="radio"
            checked={showBankCard}
            name="bankCards"
            id=""
          />
          <div>
            <label htmlFor="bankCards">
              Bank cards / Electronic money / Other
            </label>
            <div className={s.cardIcons}>
              <img src={mastercard} alt="" />
              <img src={visa} alt="" />
              <img src={pay} alt="" />
              <img src={gpay} alt="" />
            </div>
          </div>
        </div>
        <div onClick={handleCash} className={cash ? s.active : s.regularBlock}>
          <input
            checked={cash}
            type="radio"
            name="cash"
            id=""
          />
          <label style={{ top: "-5px", position: "relative" }} htmlFor="cash">
            Payment by cash to the courier
          </label>
        </div>
        <div
          onClick={handleCredit}
          className={credit ? s.active : s.regularBlock}
        >
          <input
            checked={credit}
            type="radio"
            name="card"
            id=""
          />
          <label style={{ top: "-5px", position: "relative" }} htmlFor="card">
            Payment by credit card to the courier
          </label>
        </div>
        <button className={s.confirmOrder}>Confirm order</button>
      </form>
      {/* hgvhvjvjvjvjh */}
      {showBankCard && (
        <div
          className={s.modal}
        >
          <form onSubmit={handleCardSubmit} className={s.form}>
            <div className={s.credit_card_info_form}>
              <div className={s.input_container}>
                <label htmlFor="password_field" className={s.input_label}>
                  Card holder full name
                </label>
                <input
                  id="password_field"
                  className={s.input_field}
                  type="text"
                  name="input-name"
                  title="Inpit title"
                  placeholder="Enter your full name"
                  value={cardFullName}
                  onChange={(e) => setCardFullName(e.target.value)}
                />
                {cardError.cardFullName && (
                  <span style={{color: "red"}}>{cardError.cardFullName}</span>
                )}
              </div>
              <div class={s.input_container}>
                <label htmlFor="password_field" className={s.input_label}>
                  Card Number
                </label>
                <input
                  id="password_field"
                  className={s.input_field}
                  type="number"
                  name="input-name"
                  title="Inpit title"
                  placeholder="0000 0000 0000 0000"
                  value={cardNumber}
                  onChange={(e) => {
                    if (e.target.value.length <= 16) {
                      setCardNumber(e.target.value);
                    }
                  }}
                  maxLength={16}
                />
                {cardError.cardNumber && <span style={{color: "red"}}>{cardError.cardNumber}</span>}
              </div>
              <div className={s.input_container}>
                <label htmlFor="password_field" className={s.input_label}>
                  Expiry Date / CVV
                </label>
                <div className={s.split}>
                  <input
                    id="password_field"
                    className={s.input_field}
                    type="text"
                    name="input-name"
                    title="Expiry Date"
                    placeholder="01/23"
                    value={cardDate}
                    onChange={(e) => {
                      const input = e.target.value.replace(/\D/g, "");
                      if (input.length <= 4) {
                        setCardDate(input);
                      }
                    }}
                    maxLength={4}
                  />
                  {cardError.cardDate && <span style={{color: "red"}}>{cardError.cardDate}</span>}
                  <input
                    id="password_field"
                    className={s.input_field}
                    type="number"
                    name="cvv"
                    title="CVV"
                    placeholder="CVV"
                    value={cardCVV}
                    onChange={(e) => {
                      const input = e.target.value.replace(/\D/g, "");
                      if (input.length <= 3) {
                        setCardCVV(input);
                      }
                    }}
                    maxLength={3}
                  />
                  {cardError.cardCVV && <span style={{color: "red"}}>{cardError.cardCVV}</span>}
                </div>
              </div>
            </div>
            <button class={s.purchase_btn}>Checkout</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default PaymentDetailsForm;
