import React from 'react';
import PopularDrinks from "../components/Popular_drinks";


const Drinks = () => {
    return (
        <div>
             <main>
            <PopularDrinks/>
            </main> 
        </div>
    );
}

export default Drinks;
