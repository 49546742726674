import React from 'react';
import OrderPlacement from '../components/OrderPlacement';

const OrderPlacementPage = () => {
    return (
        <div>
            <main>
            <OrderPlacement/>
            </main>
            
        </div>
    );
}

export default OrderPlacementPage;
