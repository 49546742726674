import React from 'react';
import PopularSoup from '../components/PopularSoups';

const Soups = () => {
    return (
        <div>
            <main>
            <PopularSoup/>
            </main>
            
            
        </div>
    );
}

export default Soups;
