import React from 'react';
import FreshPastries from '../components/FreshPastries';

const Pastries = () => {
    return (
        <div>
           <main>
           <FreshPastries/>
            </main> 
        </div>
    );
}

export default Pastries;
