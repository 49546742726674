import React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import s from "../styles/map.module.css";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import telephone from "../assets/img/telephone.png";
import message from "../assets/img/message.png";

const Contacts = () => {
  const position = [50.005713, 36.229195];
  const customMarkerIcon = L.icon({
    iconUrl: "https://icon-library.com/images/marker-icon/marker-icon-5.jpg",
    iconSize: [32,32],
    iconAnchor: [16,32],
    popupAnchor: [0,-32],
  })
  return (
    <div className={s.map_wraper}
    >
      <div className={s.map_box}>
        <MapContainer
          center={position}
          zoom={14}
          scrollWheelZoom={true}
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={position} icon={customMarkerIcon}>
            <Popup>
            7 Freedom Square, Kharkiv, Ukraine <br /> restaurant MIDAS <br /> Contact phone: +3 (888) 00-777-00
            </Popup>
          </Marker>
        </MapContainer>
      </div>
      <div>
        <div className={s.infoText}>
          <ul style={{ listStyleType: "none" }}>
            <li className={s.greetings}>
              See you at our restaurant:
            </li>
            <li className={s.location}>Kharkiv, Ukraine</li>
            <li className={s.location}>7 Freedom Square</li>
          </ul>
          <ul className={s.middle} style={{ listStyleType: "none" }}>
            <li className={s.greetings}>Contact phone:</li>
            <div className={s.wrapperLocation}>
              <img src={telephone} alt="" />
              <li className={s.location}>+3 (888) 00-777-00</li>
            </div>
            <div className={s.wrapperLocation}>
              <img src={telephone} alt="" />
              <li className={s.location}> +3 (888) 77-777-77</li>
            </div>
            </ul>
            <ul className={s.last} style={{ listStyleType: "none" }}>
            <li className={s.greetings}>Email address:</li>
            <div className={s.wrapperLocation}>
              <img src={message} alt="" />
              <li className={s.location}>delivery@midas.rest</li>
            </div>
            </ul>
            
          
        </div>
      </div>
    </div>
  );
};

export default Contacts;
