import React from 'react';
import s from "../styles/popular_food.module.css";
import TiramisuObject from "./../utils/tiramisu";
import cart_img from "../assets/img/cart_img.png";
import { useCart } from "../utils/context";

const Tiramisu = () => {
    // use context
  const { addToCart } = useCart();

    return (
        <div>
            <div className={s.popular__cards}>
            {TiramisuObject.map((order) => (
              <div className={s.card__tiramisu}>
                  <div className={s.card__img}>
                    <img style={{cursor: "auto"}} className={s.card__image__tiramisu} src={order.photo} alt="" />
                  </div>
                <div className={s.card__content}>
                  <div className={s.card__title__tiramisu}>{order.title}</div>
                  <div className={s.card__weight}>
                    <p>{order.weight}</p>
                  </div>
                  <div className={s.card__buy__tiramisu}>
                    <div className={s.card__price__tiramisu}>{order.price}</div>
                    <img style={{marginLeft: "6vw"}}
                      src={cart_img}
                      alt="cart_img"
                      // add to cart
                      onClick={() => addToCart(order)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
    );
}

export default Tiramisu;
