import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/pagination";
import "../styles/swipper.css";
import arrow from "../assets/img/arrow.png";
import number30 from "../assets/img/30.png";
import percent from "../assets/img/%.png";
import number20 from "../assets/img/20.png";
import hotDishTop from "../assets/img/hotDishTop.png";
import hotDishBottom from "../assets/img/hotDishBottom.png";
import soupTop from "../assets/img/soupTop.png";
import soupBottom from "../assets/img/soupBottom.png";
import khinkaliTop from "../assets/img/khinkaliTop.png";
import khinkaliBottom from "../assets/img/khinkaliBottom.png";
import saladsTop from "../assets/img/saladsTop.png";
import saladsBottom from "../assets/img/saladsBottom.png";
import snacksTop from "../assets/img/snacksTop.png";
import snacksBottom from "../assets/img/snacksBottom.png";
import { Link } from "react-router-dom";

const Swipper = () => {
  const swiperRef = useRef(null);

  const handlePrevSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNextSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <div className="swiper__container">
      <h2>Menu</h2>
      <div>
        <div className="swiper__button">
          <img
            src={arrow}
            className="swiper-button-prev"
            onClick={handlePrevSlide}
            alt=""
          ></img>
          <img
            src={arrow}
            className="swiper-button-next"
            onClick={handleNextSlide}
            alt=""
          ></img>
        </div>
      </div>

      <div className="swiper__content">
        <Swiper
          ref={swiperRef}
          slidesPerView={1}
          spaceBetween={10}
          breakpoints={{
            320: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            375: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            425: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            480: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            567: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            576: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            626: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            830: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1000: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1200: {
              slidesPerView: 5,
              spaceBetween: 20,
            },
            1400: {
              slidesPerView: 6,
              spaceBetween: 20,
            },
          }}
          className="mySwiper"
        >
          <SwiperSlide className="swiper__item1">
            <div className="specials">
              <div className="sliderSpecialsBlock">
                <img
                  style={{ width: "55%", display: "flex", margin: "auto" }}
                  src={number30}
                  alt="number30"
                />
                <img
                  style={{ width: "40%", display: "flex", margin: "auto" }}
                  src={percent}
                  alt="percent"
                />
              </div>

              <div>
                <p className="fire1">SPECIALS</p>
              </div>

              <div className="sliderSpecialsBlock">
                <img
                  style={{ width: "55%", display: "flex", margin: "auto" }}
                  src={number20}
                  alt="number20"
                />
                <img
                  style={{ width: "40%", display: "flex", margin: "auto" }}
                  src={percent}
                  alt="percent"
                />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper__item">
            <div className="hot__dish">
              <div className="sliderBlock">
                <img
                  style={{ width: "100%", display: "flex", margin: "auto" }}
                  src={hotDishTop}
                  alt="hotDishTop"
                />
              </div>

              <Link to="hot-dishes">
                <div>
                  <p className="dishName">Hot dishes</p>
                </div>
              </Link>
              <div className="sliderBlock">
                <img
                  style={{ width: "100%", display: "flex", margin: "auto" }}
                  src={hotDishBottom}
                  alt="hotDishBottom"
                />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper__item">
            <div className="hot__dish">
              <div className="sliderBlock">
                <img
                  style={{ width: "100%", display: "flex", margin: "auto" }}
                  className="soupTopimg"
                  src={soupTop}
                  alt="soupTop"
                />
              </div>

              <Link to="soups">
                <div>
                  <p className="dishName">Soups</p>
                </div>
              </Link>
              <div className="sliderBlock">
                <img
                  style={{ width: "100%", display: "flex", margin: "auto" }}
                  className="soupBottomImg"
                  src={soupBottom}
                  alt="soupBottom"
                />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper__item">
            <div className="hot__dish">
              <div className="sliderBlock">
                <img
                  style={{ width: "100%", display: "flex", margin: "auto" }}
                  src={khinkaliTop}
                  alt="hotDishTop"
                />
              </div>

              <Link to="khinkali">
                <div>
                  <p className="dishName">Khinkali</p>
                </div>
              </Link>
              <div className="sliderBlock">
                <img
                  style={{ width: "100%", display: "flex", margin: "auto" }}
                  src={khinkaliBottom}
                  alt="hotDishBottom"
                />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper__item">
            <div className="hot__dish">
              <div className="sliderBlock">
                <img
                  style={{ width: "100%", display: "flex", margin: "auto" }}
                  className="snacksTopimg"
                  src={snacksTop}
                  alt="soupTop"
                />
              </div>

              <Link to="snacks">
                <div>
                  <p className="dishName">Snacks</p>
                </div>
              </Link>
              <div className="sliderBlock">
                <img
                  style={{ width: "100%", display: "flex", margin: "auto" }}
                  className="snacksBottomImg"
                  src={snacksBottom}
                  alt="soupBottom"
                />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper__item">
            <div className="hot__dish">
              <div className="sliderBlock">
                <img
                  style={{ width: "100%", display: "flex", margin: "auto" }}
                  src={saladsTop}
                  alt="saladsTop"
                />
              </div>
              <Link to="fresh-pastries">
                <div>
                  <p className="dishName">Pastries</p>
                </div>
              </Link>

              <div className="sliderBlock">
                <img
                  style={{ width: "100%", display: "flex", margin: "auto" }}
                  src={saladsBottom}
                  alt="saladsBottom"
                />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper__item">
            <div className="hot__dish">
              <div className="sliderBlock">
                <img
                  style={{ width: "100%", display: "flex", margin: "auto" }}
                  src={hotDishTop}
                  alt="hotDishTop"
                />
              </div>
              <Link to="desserts">
                <div>
                  <p className="dishName">Desserts</p>
                </div>
              </Link>
              <div className="sliderBlock">
                <img
                  style={{ width: "100%", display: "flex", margin: "auto" }}
                  src={hotDishBottom}
                  alt="hotDishBottom"
                />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper__item">
            <div className="hot__dish">
              <div className="sliderBlock">
                <img
                  style={{ width: "100%", display: "flex", margin: "auto" }}
                  src={soupTop}
                  alt="soupTop"
                />
              </div>
              <Link to="drinks">
                <div>
                  <p className="dishName">Drinks</p>
                </div>
              </Link>
              <div className="sliderBlock">
                <img
                  style={{ width: "100%", display: "flex", margin: "auto" }}
                  src={soupBottom}
                  alt="soupBottom"
                />
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Swipper;
