const Khinkali = [
    {
        id: 73,
        photo: "https://images.bolt.eu/store/2022/2022-07-20/cca08a47-1d78-4d73-aac1-b80b25268e4d.jpeg",
        title: "Traditional Khinkali",
        weight: "350 g",
        description:
          "Traditional Khinkali is a Georgian dish known for its savory filling and unique twisted.",
        price: "5 $",
        toppings: [
          {
            name: "Sour cream",
            price: 1,
          },
          {
            name: "Extra Red pepper",
            price: 1,
          },
          {
            name: "Tomatoes and onions",
            price: 1,
          },
        ]
      },
      {
        id: 74,
        photo: "https://online-logo.s3-ap-southeast-1.amazonaws.com/homewebsite/131076/thumb_2023_08_19_12_46_22_1_Veg_fried_momo.jpg",
        title: "Fried Khinkali",
        weight: "350 g",
        description:
          "Fried Khinkali is a delightful variation of the traditional Georgian dish, Khinkali.",
        price: "6 $",
        toppings: [
          {
            name: "Tkemali sauce",
            price: 1,
          },
          {
            name: "Sour cream",
            price: 1,
          },
          {
            name: "Fresh vegetables",
            price: 1,
          },
        ]
      },
      {
        id: 75,
        photo: "https://bosskitchen.com/wp-content/uploads/2020/12/Screenshot_7-57.jpg",
        title: "Cheese Khinkali",
        weight: "350 g",
        description:
          "Cheese Khinkali is a delicious variant filled with a cheesy surprise.",
        price: "6 $",
        toppings: [
          {
            name: "Sour cream",
            price: 1,
          },
          {
            name: "Salsa",
            price: 1,
          },
          {
            name: "Green onions",
            price: 1,
          },
        ]
      },
      {
        id: 76,
        photo: "https://storage.georgia.travel/images/a-vegetarian-guide-to-georgian-food-qartuli-kerdzebis-vegetarianuli-gzamkvlevi-skhvadaskhva-khinkali.webp",
        title: "Vegetarian Khinkali",
        weight: "350 g",
        description:
          "Vegetarian Khinkali is stuffed with a mixture of vegetables and herbs.",
        price: "5 $",
        toppings: [
          {
            name: "Tahini sauce",
            price: 1,
          },
          {
            name: "Yogurt dressing",
            price: 1,
          },
          {
            name: "Chopped mint",
            price: 1,
          },
        ]
      },
      {
        id: 77,
        photo: "https://i.pinimg.com/736x/e5/94/1a/e5941af7885df89abacd09781231f767.jpg",
        title: "Spicy Khinkali",
        weight: "350 g",
        description:
          "Spicy Khinkali is filled with a spicy meat mixture for those who like a kick of heat.",
        price: "6 $",
        toppings: [
          {
            name: "Sour cream",
            price: 1,
          },
          {
            name: "Hot chili sauce",
            price: 1,
          },
          {
            name: "Cilantro",
            price: 1,
          },
        ]
      },
      {
        id: 78,
        photo: "https://georgianjournal.ge/media/images/georgianews/2015/July/Cuisine/mushroom-khinkali1.jpg",
        title: "Mushroom Khinkali",
        weight: "350 g",
        description:
          "Mushroom Khinkali is a vegetarian option filled with a savory mushroom mixture.",
        price: "5 $",
        toppings: [
          {
            name: "Garlic aioli",
            price: 1,
          },
          {
            name: "Truffle oil drizzle",
            price: 1,
          },
          {
            name: "Chopped chives",
            price: 1,
          },
        ]
      },
]
export default Khinkali;