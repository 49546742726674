import React, { useEffect, useState } from "react";
import s from "../styles/loginForm.module.css";
import { useCart } from "../utils/context";
import closeLoginIcon from "../assets/img/closeLoginIcon.png";



const LoginForm = () => {


    const {isModalOpenLogin, setIsModalOpenLogin} =useCart(true)

    const closeModal = () => {
      setIsModalOpenLogin(false);
    };
    const onEscapeKeyPress = (e) => {
      if (e.key === "Escape") {
        closeModal();
      }
    };
    useEffect(() => {
      window.addEventListener("keydown", onEscapeKeyPress);
      return () => {
        window.removeEventListener("keydown", onEscapeKeyPress);
      };
    });

  return (
<div className="wda">
      {isModalOpenLogin && (
        <div className={s.formContainer}>
 <div className={s.form}>
        <div className={s.title}>Welcome</div>
        <div className={s.subtitle}>Let's create your account!</div>
        <div className={`${s.input_container} ${s.ic1}`}>
          <input
            placeholder=""
            type="text"
            className={s.input}
            id="firstname"
          />
          <div className={s.cut}></div>
          <label className={s.iLabel} for="firstname">
            First name
          </label>
        </div>

        <div className={`${s.input_container} ${s.ic2}`}>
          <input placeholder="" type="text" className={s.input} id="lastname" />
          <div className={s.cut}></div>
          <label className={s.iLabel} for="lastname">
            Last name
          </label>
        </div>
        <div className={`${s.input_container} ${s.ic2}`}>
          <input placeholder="" type="text" className={s.input} id="email" />
          <div className={`${s.cut} ${s.cut_short}`}></div>
          <label className={s.iLabel} for="email">
            Email
          </label>
        </div>
        <button className={s.submit} type="text">
          submit
        </button>
        <button className={s.closeLoginButton} onClick={closeModal}><img src={closeLoginIcon} alt="" /></button>
      </div>
        </div>
     
      )}
    </div>
    
  );
};

export default LoginForm;
