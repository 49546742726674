const OrderedOften = [
    {
      id: 25,
      photo: "https://bosskitchen.com/wp-content/uploads/2020/12/Screenshot_1-55.jpg",
      title: "Adjarian khachapuri",
      weight: "430 g",
      description:
        "Boat-shaped khachapuri with a filling of melted sulguni cheese, eggs, and butter.",
      price: "5 $",
      toppings: [
        {
          name: "Extra adyghe cheese",
          price: 1,
        },
        {
          name: "Extra mozzarella",
          price: 1,
        },
        {
          name: "Extra feta cheese",
          price: 1,
        },
      ],
    },
    {
      id: 26,
      photo: "https://images.bolt.eu/store/2022/2022-07-20/cca08a47-1d78-4d73-aac1-b80b25268e4d.jpeg",
      title: "Traditional Khinkali",
      weight: "350 g",
      description:
        "Traditional Khinkali is a Georgian dish known for its savory filling and unique twisted.",
      price: "5 $",
      toppings: [
        {
          name: "Sour cream",
          price: 1,
        },
        {
          name: "Extra Red pepper",
          price: 1,
        },
        {
          name: "Tomatoes and onions",
          price: 1,
        },
      ],
    },
    {
      id: 27,
      photo: "https://online-logo.s3-ap-southeast-1.amazonaws.com/homewebsite/131076/thumb_2023_08_19_12_46_22_1_Veg_fried_momo.jpg",
      title: "Fried Khinkali",
      weight: "350 g",
      description:
        "Fried Khinkali is a delightful variation of the traditional Georgian dish, Khinkali.",
      price: "6 $",
      toppings: [
        {
          name: "Tkemali sauce",
          price: 1,
        },
        {
          name: "Sour cream",
          price: 1,
        },
        {
          name: "Fresh vegetables",
          price: 1,
        },
      ],
    },
  ];
  
  export default OrderedOften;
  