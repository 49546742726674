// import React, { useState, useEffect } from "react";
// import s from "../styles/popular_drinks.module.css";

// function Drinks() {
//   const [data, setData] = useState({ error: null, isLoaded: false, items: [] });

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch(
//           "https://www.thecocktaildb.com/api/json/v1/1/filter.php?c=Cocktail"
//         );
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
//         const result = await response.json();
//         setData({ isLoaded: true, items: result.drinks, error: null });
//       } catch (error) {
//         setData({ isLoaded: true, items: [], error });
//       }
//     };

//     fetchData();
//   }, []);

//   const { error, isLoaded, items } = data;

//   if (error) {
//     return <p>Error: {error.message}</p>;
//   } else if (!isLoaded) {
//     return <p>Loading...</p>;
//   } else {
//     return (
//       <div>
//         <h2>Popular drinks</h2>
//       <div className={s.popular}>
//         {items.map((item) => (
//           <div className={s.wrapper} key={item.idDrink}>
//             <div className={s.name}>
//             <p>{item.strDrink}</p>
//             </div>
//             <div className={s.picture}>
//             <img className={s.drink_card} src={item.strDrinkThumb} alt="" />
//             </div>
//           </div>

//         ))}
//       </div>
//       </div>
//     );
//   }
// }

// export default Drinks;

import React, { useState } from "react";
import s from "../styles/popular_desserts.module.css";
import cart_img from "../assets/img/cart_img.png";
import drinks from './../utils/popular_drinks';
import { Link } from "react-router-dom";
import { useCart } from "../utils/context";

const PopularDrinks = () => {

// use context
const {addToCart} = useCart();

const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <div>
      <div className={s.popular}>
        <div className={s.popular__container}>
        <div className={s.main_box}>
        <div className={s.popular__title}><span>Drinks</span></div>
          <form className={s.input_container}>
            <input className={s.search_input}
              value={searchText}
              onChange={handleSearch}
              type="text"
              placeholder="search"
            />
          </form>
          </div>
          <div className={s.popular__cards}>
            {drinks.filter((drink) =>
              drink.title
                .toLocaleLowerCase()
                .includes(searchText.toLocaleLowerCase())
            ).map((drink) => (
              <div className={s.card}> 
              <Link to={`/drinks/${drink.id}`} className={s.card} key={drink.id}>
                <div className={s.card__img}>
                  <img className={s.card__image} src={drink.photo} alt="" />
                </div>
                </Link>
                <div className={s.card__content}>
                  <div className={s.card__title}>{drink.title}</div>
                  <div className={s.card__weight}>
                    <p>{drink.amount}</p>
                  </div>
                  <div className={s.card__description}>
                    {drink.description}
                  </div>
                  <div className={s.card__buy}>
                    <div className={s.card__price}>{drink.price}</div>
                    <img src={cart_img} alt="cart_img"
                    // add to cart
                    onClick={() =>addToCart(drink)}
                     />
                  </div>
                </div>
                </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopularDrinks;
