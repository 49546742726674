const Pastries = [
    {
      id: 55,
      title: "Croissant",
      description: "Classic French pastry made with butter and flaky layers",
      price: "3 $" ,
      weight: "80 g",
      ingredients: ["flour", "butter", "yeast", "sugar", "eggs"],
      photo: "https://static01.nyt.com/images/2021/04/07/dining/06croissantsrex1/merlin_184841898_ccc8fb62-ee41-44e8-9ddf-b95b198b88db-articleLarge.jpg",
      toppings: [
        {
          name: "Cup of green tea",
          price: 1,
        },
        {
          name: "Cup of black tea",
          price: 1,
        },
        {
          name: "Cup of coffee",
          price: 2,
        },
      ] 
    },
    {
      id: 56,
      title: "Danish Pastry",
      description: "Flaky pastry filled with sweet fillings like fruit or cream cheese",
      price: "3 $" ,
      weight: "100 g",
      ingredients: ["flour", "butter", "sugar", "eggs", "fruit jam"],
      photo: "https://nordicfoodliving.com/wp-content/uploads/2017/11/38395858281_8cba7f3994_b-1.jpg",
      toppings: [
        {
          name: "Cup of green tea",
          price: 1,
        },
        {
          name: "Cup of black tea",
          price: 1,
        },
        {
          name: "Cup of coffee",
          price: 2,
        },
      ] 
    },
    {
      id: 57,
      title: "Eclair",
      description: "Long, thin pastry filled with cream and topped with icing",
      price: "5 $" ,
      weight: "90 g",
      ingredients: ["choux pastry", "cream", "chocolate icing"],
      photo: "https://www.seriouseats.com/thmb/f3kx43qc--aS4-okr8VNpnSAZZQ=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/__opt__aboutcom__coeus__resources__content_migration__serious_eats__seriouseats.com__2020__12__20201210-choux-eclairs-vicky-wasik-16-acf615b81c2f4217857bbf80d60c28c1.jpg",
      toppings: [
        {
          name: "Cup of green tea",
          price: 1,
        },
        {
          name: "Cup of black tea",
          price: 1,
        },
        {
          name: "Cup of coffee",
          price: 2,
        },
      ] 
    },
    {
      id: 58,
      title: "Palmier",
      description: "Sweet, crispy pastry shaped like a palm leaf",
      price: "2 $" ,
      weight: "60 g",
      ingredients: ["puff pastry", "sugar"],
      photo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQD9IkAVe_fckMbQq833vWeFy9cxRcUs8JImA&usqp=CAU",
      toppings: [
        {
          name: "Cup of green tea",
          price: 1,
        },
        {
          name: "Cup of black tea",
          price: 1,
        },
        {
          name: "Cup of coffee",
          price: 2,
        },
      ] 
    },
    {
      id: 59,
      title: "Mille-Feuille",
      description: "Layers of puff pastry with cream filling and powdered sugar",
      price: "5 $" ,
      weight: "120 g",
      ingredients: ["puff pastry", "cream", "powdered sugar"],
      photo: "https://chefin.com.au/wp-content/uploads/2019/02/mille-feuille-3.jpg",
      toppings: [
        {
          name: "Cup of green tea",
          price: 1,
        },
        {
          name: "Cup of black tea",
          price: 1,
        },
        {
          name: "Cup of coffee",
          price: 2,
        },
      ] 
    },
    {
      id: 60,
      title: "Baklava",
      description: "Sweet pastry made of layers of filo dough, filled with nuts and honey",
      price: "4 $" ,
      weight: "70 g",
      ingredients: ["filo dough", "nuts", "honey", "butter"],
      photo: "https://falasteenifoodie.com/wp-content/uploads/2023/03/DSC08724-scaled.jpg",
      toppings: [
        {
          name: "Cup of green tea",
          price: 1,
        },
        {
          name: "Cup of black tea",
          price: 1,
        },
        {
          name: "Cup of coffee",
          price: 2,
        },
      ] 
    },
    {
      id: 61,
      title: "Strudel",
      description: "Thin pastry filled with fruits and spices, often served warm",
      price: "4 $" ,
      weight: "110 g",
      ingredients: ["filo dough", "fruit", "cinnamon", "sugar"],
      photo: "https://images-gmi-pmc.edge-generalmills.com/52c54c0f-fb55-483b-bbfb-2e1ac8bf4fc9.jpg",
      toppings: [
        {
          name: "Cup of green tea",
          price: 1,
        },
        {
          name: "Cup of black tea",
          price: 1,
        },
        {
          name: "Cup of coffee",
          price: 2,
        },
      ] 
    },
    {
      id: 62,
      title: "Scone",
      description: "British pastry often served with tea, with a crumbly texture",
      price: "2 $" ,
      weight: "90 g",
      ingredients: ["flour", "butter", "sugar", "milk"],
      photo: "https://theviewfromgreatisland.com/wp-content/uploads/2019/07/peanut-butter-chocolate-chip-scones-8500679-July-23-2019.jpg",
      toppings: [
        {
          name: "Cup of green tea",
          price: 1,
        },
        {
          name: "Cup of black tea",
          price: 1,
        },
        {
          name: "Cup of coffee",
          price: 2,
        },
      ] 
    },
    {
      id: 63,
      title: "Cannoli",
      description: "Italian pastry tube filled with sweet ricotta cheese",
      price: "5 $" ,
      weight: "80 g",
      ingredients: ["pastry shell", "ricotta cheese", "sugar", "chocolate chips"],
      photo: "https://i0.wp.com/memoriediangelina.com/wp-content/uploads/2023/01/Cannoli-2.jpg?fit=800%2C534&ssl=1",
      toppings: [
        {
          name: "Cup of green tea",
          price: 1,
        },
        {
          name: "Cup of black tea",
          price: 1,
        },
        {
          name: "Cup of coffee",
          price: 2,
        },
      ] 
    },
    {
      id: 64,
      title: "Pretzel",
      description: "Soft or crunchy twisted bread pastry often topped with salt",
      price: "3 $" ,
      weight: "100 g",
      ingredients: ["flour", "yeast", "salt", "baking soda"],
      photo: "https://www.cuisinart.com/globalassets/recipes/recipe_805_2143631880.jpg",
      toppings: [
        {
          name: "Cup of green tea",
          price: 1,
        },
        {
          name: "Cup of black tea",
          price: 1,
        },
        {
          name: "Cup of coffee",
          price: 2,
        },
      ] 
    },
    {
        id: 65,
        title: "Puff Pastry Pinwheels",
        description: "Flaky pinwheels filled with sweet or savory ingredients",
        price: "4 $" ,
        weight: "70 g",
        ingredients: ["puff pastry", "filling of choice"],
        photo: "https://www.allrecipes.com/thmb/i_sQSwylz4DibOaqHQ47BIHGoy0=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/5736995-easy-3-ingredient-cheese-pinwheels-Happyschmoopies-4x3-1-00dfb49e64934c2cbdb9ed71a1980511.jpg",
        toppings: [
          {
            name: "Cup of green tea",
            price: 1,
          },
          {
            name: "Cup of black tea",
            price: 1,
          },
          {
            name: "Cup of coffee",
            price: 2,
          },
        ] 
      },
      {
        id: 66,
        title: "Almond Croissant",
        description: "Croissant filled with almond paste and topped with sliced almonds",
        price: "4 $" ,
        weight: "90 g",
        ingredients: ["flour", "butter", "almond paste", "sliced almonds"],
        photo: "https://static01.nyt.com/images/2021/04/07/dining/06croissantsrex4-almond/merlin_184842117_321090da-ce3a-4c4d-9e6a-3461506a39d3-articleLarge.jpg",
        toppings: [
          {
            name: "Cup of green tea",
            price: 1,
          },
          {
            name: "Cup of black tea",
            price: 1,
          },
          {
            name: "Cup of coffee",
            price: 2,
          },
        ] 
      },
      {
        id: 67,
        title: "Cinnamon Roll",
        description: "Sweet roll filled with cinnamon sugar and topped with icing",
        price: "3 $" ,
        weight: "80 g",
        ingredients: ["yeast dough", "cinnamon sugar", "icing"],
        photo: "https://crustandbeyond.com/pickup/wp-content/uploads/sites/2/2017/03/pastry-Cinnamon-Roll-01-CRUST-BAKERY-FENTON-MI-2960x2000.jpg",
        toppings: [
          {
            name: "Cup of green tea",
            price: 1,
          },
          {
            name: "Cup of black tea",
            price: 1,
          },
          {
            name: "Cup of coffee",
            price: 2,
          },
        ] 
      },
      {
        id: 68,
        title: "Linzertorte",
        description: "Austrian pastry tart with a crumbly almond crust and raspberry jam filling",
        price: "4 $" ,
        weight: "120 g",
        ingredients: ["almond crust", "raspberry jam"],
        photo: "https://www.alnatura.de/-/media/Images/Common/Recipe-Images/1/10/102/1023/102316_Linzertorte_Quelle_Alnatura.jpg?mw=960&hash=DF394EB3A5ECA58DA60802CE45FFD299",
        toppings: [
          {
            name: "Cup of green tea",
            price: 1,
          },
          {
            name: "Cup of black tea",
            price: 1,
          },
          {
            name: "Cup of coffee",
            price: 2,
          },
        ] 
      },
      {
        id: 69,
        title: "Churro",
        description: "Spanish fried dough pastry, often coated in cinnamon sugar",
        price: "3 $" ,
        weight: "60 g",
        ingredients: ["flour", "water", "sugar", "cinnamon"],
        photo: "https://vegnews.com/media/W1siZiIsIjI0Njg5L1ZlZ05ld3MuQ2h1cnJvcy5qcGciXSxbInAiLCJ0aHVtYiIsIjEzNjB4ODA0IyIseyJmb3JtYXQiOiJqcGcifV0sWyJwIiwib3B0aW1pemUiXV0/VegNews.Churros.jpg?sha=3868f3a3467c856d",
        toppings: [
          {
            name: "Cup of green tea",
            price: 1,
          },
          {
            name: "Cup of black tea",
            price: 1,
          },
          {
            name: "Cup of coffee",
            price: 2,
          },
        ] 
      },
      {
        id: 70,
        title: "Rugelach",
        description: "Jewish pastry filled with nuts, raisins, and fruit preserves",
        price: "3 $" ,
        weight: "70 g",
        ingredients: ["flour", "butter", "nuts", "raisins", "fruit preserves"],
        photo: "https://static01.nyt.com/images/2016/11/30/dining/30COOKING-RUGELACH1/30COOKING-RUGELACH1-articleLarge.jpg",
        toppings: [
          {
            name: "Cup of green tea",
            price: 1,
          },
          {
            name: "Cup of black tea",
            price: 1,
          },
          {
            name: "Cup of coffee",
            price: 2,
          },
        ] 
      },
      {
        id: 71,
        title: "Custard Tart",
        description: "Creamy custard-filled pastry tart with a flaky crust",
        price: "4 $" ,
        weight: "100 g",
        ingredients: ["pastry crust", "custard filling"],
        photo: "https://img.taste.com.au/T6IvSEFv/taste/2022/04/mini-fantale-custard-tarts-178245-2.jpg",
        toppings: [
          {
            name: "Cup of green tea",
            price: 1,
          },
          {
            name: "Cup of black tea",
            price: 1,
          },
          {
            name: "Cup of coffee",
            price: 2,
          },
        ] 
      },
      {
        id: 72,
        title: "Pecan Pie",
        description: "Southern-style pie with a sweet, nutty filling in a pastry crust",
        price: "5 $" ,
        weight: "120 g",
        ingredients: ["pastry crust", "pecans", "sugar", "corn syrup"],
        photo: "https://img.taste.com.au/xRvF1Ybi/taste/2016/11/pecan-pie-63267-1.jpeg",
        toppings: [
          {
            name: "Cup of green tea",
            price: 1,
          },
          {
            name: "Cup of black tea",
            price: 1,
          },
          {
            name: "Cup of coffee",
            price: 2,
          },
        ] 
      },
  ];

export default Pastries;