const snacks = [
    {
      id: 7,
      title: "Potato Chips",
      description: "Crispy and salty potato chips",
      price: "2 $" ,
      weight: "40 g",
      ingredients: ["potatoes", "salt", "vegetable oil"],
      photo: "https://images.food52.com/9m7_LoeOZbwcDZhqKoyU3yf1atk=/1200x675/ec8ee48e-c77a-48c3-a9e0-d2191111bc9a--2021-0723_food52-12th-birthday_homemade-potato-chips_3x2_ty-mecham.jpg",
      toppings: [
        {
          name: "Double size",
          price: 1,
        },
        {
          name: "Extra sauce",
          price: 1,
        },
        {
          name: "Glass of beer",
          price: 5,
        },
      ] 
    },
    {
      id: 8,
      title: "Pretzels",
      description: "Crunchy pretzel twists",
      price: "2 $" ,
      weight: "50 g",
      ingredients: ["flour", "yeast", "salt"],
      photo: "https://static01.nyt.com/images/2023/04/14/multimedia/14PRETZELSREX-hvtc/13PRETZELSREX-hvtc-threeByTwoMediumAt2X.jpg",
      toppings: [
        {
          name: "Double size",
          price: 1,
        },
        {
          name: "Extra sauce",
          price: 1,
        },
        {
          name: "Glass of beer",
          price: 5,
        },
      ] 
    },
    {
      id: 9,
      title: "Popcorn",
      description: "Classic buttered popcorn",
      price: "2 $" ,
      weight: "60 g",
      ingredients: ["popcorn kernels", "butter", "salt"],
      photo: "https://www.simplyrecipes.com/thmb/Xzggu-Md45HKhhYSw4DK8tGlZ_I=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/Simply-Recipes-Perfect-Popcorn-LEAD-41-4a75a18443ae45aa96053f30a3ed0a6b.JPG",
      toppings: [
        {
          name: "Double size",
          price: 1,
        },
        {
          name: "Extra salt/sugar",
          price: 1,
        },
        {
          name: "Glass of beer",
          price: 5,
        },
      ] 
    },
    {
      id: 10,
      title: "Trail Mix",
      description: "A mix of nuts, dried fruits, and chocolate",
      price: "3 $" ,
      weight: "70 g",
      ingredients: ["almonds", "raisins", "M&M's", "cashews"],
      photo: "https://kaynutrition.com/wp-content/uploads/2015/08/Trail-Mix.jpg",
      toppings: [
        {
          name: "Double size",
          price: 2,
        },
        {
          name: "Glass of juice",
          price: 1,
        },
        {
          name: "Glass of beer",
          price: 5,
        },
      ] 
    },
    {
      id: 11,
      title: "Cheese and Crackers",
      description: "Assorted cheese and crackers",
      price: "4 $" ,
      weight: "80 g",
      ingredients: ["cheese slices", "crackers", "grapes"],
      photo: "https://www.manusmenu.com/wp-content/uploads/2014/12/Cheese-Mouse-on-Cracker-2-1-of-1.jpg",
      toppings: [
        {
          name: "Double size",
          price: 2,
        },
        {
          name: "Glass of wine",
          price: 5,
        },
        {
          name: "Glass of beer",
          price: 5,
        },
      ] 
    },
    {
      id: 12,
      title: "Granola Bars",
      description: "Healthy granola bars with oats and dried fruits",
      price: "3 $" ,
      weight: "40 g",
      ingredients: ["oats", "honey", "nuts", "dried fruits"],
      photo: "https://i2.wp.com/www.downshiftology.com/wp-content/uploads/2022/08/Granola-Bars-main-1.jpg",
      toppings: [
        {
          name: "Double size",
          price: 2,
        },
        {
          name: "Cup of tea",
          price: 1,
        },
        {
          name: "Cup of coffee",
          price: 2,
        },
      ] 
    },
    {
      id: 13,
      title: "Chocolate Almonds",
      description: "Roasted almonds coated in milk chocolate",
      price: "5 $" ,
      weight: "60 g",
      ingredients: ["almonds", "milk chocolate"],
      photo: "https://anitalianinmykitchen.com/wp-content/uploads/2022/10/choc-almonds-sq-1-of-1.jpg",
      toppings: [
        {
          name: "Double size",
          price: 3,
        },
        {
          name: "Cup of tea",
          price: 1,
        },
        {
          name: "Cup of coffee",
          price: 2,
        },
      ] 
    },
    {
      id: 14,
      title: "Fruit Gummies",
      description: "Assorted fruit-flavored gummy candies",
      price: "2 $" ,
      weight: "50 g",
      ingredients: ["gelatin", "fruit juice", "sugar"],
      photo: "https://s20427.pcdn.co/wp-content/uploads/2017/03/Delicious-Homemade-Fruit-Snacks-Recipe.jpg",
      toppings: [
        {
          name: "Double size",
          price: 1,
        },
        {
          name: "Cup of tea",
          price: 1,
        },
        {
          name: "Cup of coffee",
          price: 2,
        },
      ] 
    },
    {
        id: 15,
        title: "Sliced Fruit Platter",
        description: "Assorted sliced fruits, perfect for a healthy snack",
        price: "6 $" ,
        weight: "150 g",
        ingredients: ["apples", "oranges", "grapes", "melon"],
        photo: "https://unpeeledjournal.com/wp-content/uploads/2020/05/49857268781_7c4a135912_z-1.jpg",
        toppings: [
          {
            name: "Extra oranges",
            price: 1,
          },
          {
            name: "Extra apples",
            price: 1,
          },
          {
            name: "Extra grapes",
            price: 1,
          },
        ] 
      },
      {
        id: 16,
        title: "Mixed Nuts",
        description: "A blend of roasted mixed nuts, including almonds, cashews, and peanuts",
        price: "4 $" ,
        weight: "90 g",
        ingredients: ["almonds", "cashews", "peanuts", "salt"],
        photo: "https://satvikk.com/wp-content/uploads/2022/08/mixedbuts.jpg",
        toppings: [
          {
            name: "Extra almonds",
            price: 1,
          },
          {
            name: "Extra cashews",
            price: 1,
          },
          {
            name: "Extra peanuts",
            price: 1,
          },
        ] 
      },
      {
        id: 17,
        title: "Mini Cheeseburgers",
        description: "Miniature cheeseburgers with a side of ketchup",
        price: "5 $" ,
        weight: "100 g",
        ingredients: ["mini burger buns", "beef patties", "cheese", "ketchup"],
        photo: "https://pizzazzerie.com/wp-content/uploads/2015/08/party-food-mini-cheeseburgers-pizzazzerie-6.jpg",
        toppings: [
          {
            name: "Extra cheese",
            price: 1,
          },
          {
            name: "Extra sauce",
            price: 1,
          },
          {
            name: "Extra beef patties",
            price: 1,
          },
        ] 
      },
      {
        id: 18,
        title: "Veggie Sticks",
        description: "Creamy hummus with carrot and celery sticks",
        price: "4 $" ,
        weight: "80 g",
        ingredients: ["chickpeas", "tahini", "carrots", "celery"],
        photo: "https://cadryskitchen.com/wp-content/uploads/2020/12/hor-vegetable-party-platter-hummus.jpg",
        toppings: [
          {
            name: "Extra carrots",
            price: 1,
          },
          {
            name: "Extra celery",
            price: 1,
          },
          {
            name: "Extra chickpeas",
            price: 1,
          },
        ] 
      },
      {
        id: 19,
        title: "Rice Cakes",
        description: "Light and crunchy rice cakes spread with peanut butter",
        price: "3 $" ,
        weight: "60 g",
        ingredients: ["rice cakes", "peanut butter"],
        photo: "https://cdn.foodloversmarket.co.za/wp-content/uploads/20230324143638/fl_peanut-butter-cups_website-thumbnail-672x378-1.jpg",
        toppings: [
          {
            name: "Extra peanut butter",
            price: 1,
          },
          {
            name: "Extra chocolate",
            price: 1,
          },
          {
            name: "Extra rice cakes",
            price: 1,
          },
        ] 
      },
      {
        id: 20,
        title: "Cheddar Cheese Cubes",
        description: "Cubes of sharp cheddar cheese",
        price: "3 $" ,
        weight: "70 g",
        ingredients: ["cheddar cheese"],
        photo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdkjEF43QbogSm-RdE2mfJKVTD68F_75OF-zon8wd6jwMJp5AktEmtkTVnoKyUDeU-824&usqp=CAU",
        toppings: [
          {
            name: "Extra Cheese Cubes",
            price: 1,
          },
          {
            name: "Glass of white wine",
            price: 5,
          },
          {
            name: "Glass of red wine",
            price: 5,
          },
        ] 
      },
      {
        id: 21,
        title: "Dried Mango Slices",
        description: "Sweet and chewy dried mango slices",
        price: "4 $" ,
        weight: "80 g",
        ingredients: ["dried mango"],
        photo: "https://royalfantasy.in/cdn/shop/products/Mango-1.jpg?v=1627638488",
        toppings: [
          {
            name: "Extra mango slices",
            price: 1,
          },
          {
            name: "Cup of green tea",
            price: 1,
          },
          {
            name: "Cup of black tea",
            price: 1,
          },
        ] 
      },
      {
        id: 22,
        title: "Mini Pizza Bites",
        description: "Tiny pizza bites with tomato sauce and cheese",
        price: "4 $" ,
        weight: "90 g",
        ingredients: ["mini pizza dough", "tomato sauce", "mozzarella cheese"],
        photo: "https://images.immediate.co.uk/production/volatile/sites/30/2020/08/polenta-pizza-bites-0e27b1f.jpg?quality=90&resize=440,400",
        toppings: [
          {
            name: "Extra tomato sauce",
            price: 1,
          },
          {
            name: "Mozzarella cheese",
            price: 1,
          },
          {
            name: "Extra slices",
            price: 1,
          },
        ] 
      },
      {
        id: 23,
        title: "Mixed Berries Bowl",
        description: "A mix of fresh berries, including strawberries, blueberries, and raspberries",
        price: "5 $" ,
        weight: "120 g",
        ingredients: ["strawberries", "blueberries", "raspberries"],
        photo: "https://www.courtneyssweets.com/wp-content/uploads/2016/01/Berry-Yogurt-Breakfast-Bowl-Recipe1.jpg",
        toppings: [
          {
            name: "Extra strawberries",
            price: 1,
          },
          {
            name: "Extra blueberries",
            price: 1,
          },
          {
            name: "Extra raspberries",
            price: 1,
          },
        ] 
      },
      {
        id: 24,
        title: "Greek Yogurt Parfait",
        description: "Layered parfait with Greek yogurt, granola, and honey",
        price: "5 $" ,
        weight: "150 g",
        ingredients: ["Greek yogurt", "granola", "honey"],
        photo: "https://images.slurrp.com/prod/recipe_images/transcribe/breakfast/Greek-Yoghurt-Fruit-Parfait.webp",
        toppings: [
          {
            name: "Extra honey",
            price: 1,
          },
          {
            name: "Extra granola",
            price: 1,
          },
          {
            name: "Extra Greek yogurt",
            price: 1,
          },
        ] 
      },
  ];

export default snacks;