import React, { useState } from "react";
import s from "../styles/shippingDetails.module.css";

const ShippingDetailsForm1 = (props) => {
  const [formData, setFormData] = useState({
    street: "",
    building: "",
    apartment: "",
  });

  

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!formData.street.trim()) {
      newErrors.street = "Street is required";
    }
    if (!formData.building.trim()) {
      newErrors.building = "Building is required";
    }
    if (!formData.apartment.trim()) {
      newErrors.apartment = "Apartment is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      alert("Form has been sent successfully");
      props.toggleShippingDetails()
    } else {
      alert("Form hasn't been sent successfully");
    }
    setFormData({
      street: "",
      building: "",
      apartment: "",
    });
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
    setErrors({ ...errors, [id]: "" });
  };

  return (
    <div>
      <span className={s.addresShipping}>Shipping address</span>
      <form
        onSubmit={handleSubmit}
        id="contact_form"
        className={s.shippingFormFirstOption}
        action=""
      >
        <div className={s.street_box}>
          <label htmlFor="street">Street</label>
          <input
            className={s.shippingStreet}
            type="text"
            id="street"
            value={formData.street}
            onChange={handleChange}
          />
          {errors.street && <p className={s.checkStreet}>{errors.street}</p>}
        </div>

        <div className={s.building_box}>
          <label htmlFor="building">building</label>
          <input
            className={s.house}
            type="text"
            id="building"
            value={formData.building}
            onChange={handleChange}
          />
          {errors.building && <p className={s.checkBuilding}>{errors.building}</p>}
          <label htmlFor="apartment">apartment</label>
          <input
            className={s.apartment}
            type="text"
            id="apartment"
            value={formData.apartment}
            onChange={handleChange}
          />
          {errors.apartment && (
            <p className={s.checkApartment}>{errors.apartment}</p>
          )}
        </div>
        <div className={s.order_box} style={{ display: "flex" }}>
          <label className={s.coments} htmlFor="textarea">
            Order <br /> comment
          </label>
          <textarea></textarea>
        </div>

        <div className={s.wrapperContinueShipping}>
          <button className={s.continueShipping}>continue</button>
          <span className={s.remainShipping}>Last step remaining</span>
        </div>
      </form>
    </div>
  );
};

export default ShippingDetailsForm1;
