import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HotDishes from "./pages/Hot_dishes";
import Snacks from "./pages/Snacks";
import FreshPastries from "./pages/Fresh_pastries";
import Desserts from "./pages/Desserts";
import Drinks from "./pages/Drinks";
import Feedback from "./pages/Feedback";
import ItemDescription from "./pages/Description/ItemDescription";
import DrinksDescription from "./pages/Description/DrinksDescription";
import SnacksDescription from "./pages/Description/SnacksDescription";
import FreshPastryDescription from "./pages/Description/FreshPastryDescription";
import DessertsDescription from "./pages/Description/DessertsDescription";
import PopularFoodDescription from "./pages/Description/PopularFoodDescription";
import SoupsDescription from "./pages/Description/SoupsDescription";
import KhinkaliDescription from "./pages/Description/KhinkaliDescription";
import SoupsPage from "./pages/Soups";
import KhinkaliPage from "./pages/KhinkaliPage";
import Delivery from "./pages/Delivery";
import { CartProvider } from "./utils/context";
import CartModal from "./components/Cart_modal";
import LoginForm from "./components/LoginForm";
import OrderPlacementPage from "./pages/OrderPlacementPage";
import PaymentPage from "./pages/PaymentPage";
import ContactsPage from "./pages/ContactsPage";

function App() {
  return (
    <CartProvider>
      <div className="app">
        <div className="app__container">
          <BrowserRouter>
            <Header />
            <CartModal />
            <LoginForm />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route
                path="/popular-food/:itemId"
                element={<PopularFoodDescription />}
              />
              <Route path="/hot-dishes" element={<HotDishes />} />
              <Route path="/hot-dishes/:itemId" element={<ItemDescription />} />

              <Route path="/soups" element={<SoupsPage />} />
              <Route path="/soups/:itemId" element={<SoupsDescription />} />

              <Route path="/khinkali" element={<KhinkaliPage />} />
              <Route
                path="/khinkali/:itemId"
                element={<KhinkaliDescription />}
              />

              <Route path="/snacks" element={<Snacks />} />
              <Route path="/snacks/:itemId" element={<SnacksDescription />} />
              <Route path="/fresh-pastries" element={<FreshPastries />} />
              <Route
                path="/fresh-pastries/:itemId"
                element={<FreshPastryDescription />}
              />
              <Route path="/desserts" element={<Desserts />} />
              <Route
                path="/desserts/:itemId"
                element={<DessertsDescription />}
              />
              <Route path="/drinks" element={<Drinks />} />
              <Route path="/drinks/:itemId" element={<DrinksDescription />} />
              <Route path="/feedback" element={<Feedback />} />
              <Route path="/delivery" element={<Delivery />} />
              <Route path="/payment" element={<PaymentPage />} />
              <Route path="/cart" element={<CartModal />} />
              <Route path="/orderPlacement" element={<OrderPlacementPage />} />
              <Route path="/contacts" element={<ContactsPage />} />
            </Routes>
            <Footer />
          </BrowserRouter>
        </div>
      </div>
    </CartProvider>
  );
}

export default App;
