import React, { useState } from 'react';
import s from "../styles/soups.module.css";
import cart_img from "../assets/img/cart_img.png";
import SoupsPop from '../utils/soups';
import { Link } from "react-router-dom";
import { useCart } from "../utils/context";

const Soups = () => {

  // use context
const {addToCart} = useCart();

const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

    return (
        <div>
          <div className={s.popular}>
            <div className={s.popular__container}>
            <div className={s.main_box}>
            <div className={s.popular__title}><span>Soups</span></div>
          <form className={s.input_container}>
            <input className={s.search_input}
              value={searchText}
              onChange={handleSearch}
              type="text"
              placeholder="search"
            />
          </form>
          </div>
              <div className={s.popular__cards}>
                {SoupsPop.filter((soup) =>
              soup.title
                .toLocaleLowerCase()
                .includes(searchText.toLocaleLowerCase())
            ).map((soup) => (
                  <div className={s.card}>
                  <Link to={`/soups/${soup.id}`} className={s.card} key={soup.id}>
                    <div className={s.card__img}>
                      <img className={s.card__image} src={soup.photo} alt="" />
                    </div>
                    </Link>
                    <div className={s.card__content}>
                      <div className={s.card__title}>{soup.title}</div>
                      <div className={s.card__weight}>
                        <p>{soup.weight}</p>
                      </div>
                      <div className={s.card__description}>
                        {soup.description}
                      </div>
                      <div className={s.card__buy}>
                        <div className={s.card__price}>{soup.price}</div>
                        <img src={cart_img} alt="cart_img"
                        // add to cart
                    onClick={() =>addToCart(soup)} />
                      </div>
                    </div>
                    </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
}

export default Soups;
