const Populars = [
  {
    id: 79,
    photo: "https://bosskitchen.com/wp-content/uploads/2020/12/Screenshot_1-55.jpg",
    title: "Adjarian khachapuri",
    weight: "430 g",
    description:
      "Boat-shaped khachapuri with a filling of melted sulguni cheese, eggs, and butter.",
    price: "5 $",
    toppings: [
      {
        name: "Extra adyghe cheese",
        price: 1,
      },
      {
        name: "Extra mozzarella",
        price: 1,
      },
      {
        name: "Extra feta cheese",
        price: 1,
      },
    ]
  },
  {
    id: 80,
    photo: "https://images.bolt.eu/store/2022/2022-07-20/cca08a47-1d78-4d73-aac1-b80b25268e4d.jpeg",
    title: "Traditional Khinkali",
    weight: "350 g",
    description:
      "Traditional Khinkali is a Georgian dish known for its savory filling and unique twisted.",
    price: "5 $",
    toppings: [
      {
        name: "Sour cream",
        price: 1,
      },
      {
        name: "Extra Red pepper",
        price: 1,
      },
      {
        name: "Tomatoes and onions",
        price: 1,
      },
    ]
  },
  {
    id: 81,
    photo: "https://online-logo.s3-ap-southeast-1.amazonaws.com/homewebsite/131076/thumb_2023_08_19_12_46_22_1_Veg_fried_momo.jpg",
    title: "Fried Khinkali",
    weight: "350 g",
    description:
      "Fried Khinkali is a delightful variation of the traditional Georgian dish, Khinkali.",
    price: "6 $",
    toppings: [
      {
        name: "Tkemali sauce",
        price: 1,
      },
      {
        name: "Sour cream",
        price: 1,
      },
      {
        name: "Fresh vegetables",
        price: 1,
      },
    ]
  },
  {
    id: 82,
    photo: "https://betterme.world/articles/wp-content/uploads/2021/06/shutterstock_710495671.jpg",
    title: "Low-calorie lunch",
    weight: "450 g",
    description:
      "It includes lean protein sources like grilled chicken,tofu, plenty of vegetables. ",
    price: "8 $",
    toppings: [
      {
        name: "Water or herbal tea",
        price: 1,
      },
      {
        name: "Nuts or seeds",
        price: 1,
      },
      {
        name: "Extra fruits",
        price: 1,
      },
    ]
  },
  {
    id: 83,
    photo: "https://foodperestroika.com/wp-content/uploads/2015/05/Khachapuri4-featured.jpg",
    title: "Mingrelian khachapuri",
    weight: "430 g",
    description:
      "Mingrelian Khachapuri is a traditional Georgian cheese-filled bread dish.",
    price: "6 $",
    toppings: [
      {
        name: "Tkemali sauce",
        price: 1,
      },
      {
        name: "Extra Red pepper",
        price: 1,
      },
      {
        name: "Tomatoes and onions",
        price: 1,
      },
    ]
  },
  {
    id: 84,
    photo: "https://www.dimitrasdishes.com/wp-content/uploads/2019/05/beffteki-red-3_orig-720x405.jpg",
    title: "Country-style meatballs",
    weight: "350 g",
    description:
      "Classic dish made from beef, mixed with various seasonings and ingredients. ",
    price: "5 $",
    toppings: [
      {
        name: "Mashed potatoes",
        price: 1,
      },
      {
        name: "Vegetables",
        price: 1,
      },
      {
        name: "Gravy",
        price: 1,
      },
    ]
  },
  {
    id: 85,
    photo: "https://georgianjournal.ge/media/images/georgianews/2015/December/Cuisine/kharcho%20georgian%20beef-walnut%20soup%20with%20fresh%20herbs.jpeg",
    title: "Georgian kharcho",
    weight: "350 g",
    description:
      "The key ingredients: beef, lamb, chicken, rice, blend of seasonings like garlic, coriander.",
    price: "5 $",
    toppings: [
      {
        name: "Garlic sauce",
        price: 1,
      },
      {
        name: "Cilantro and parsley",
        price: 1,
      },
      {
        name: "Lavash",
        price: 1,
      },
    ]
  },
  {
    id: 86,
    photo: "https://1000worldrecipes.com/images/recipes/georgian-vegetable-soup/b601db81.jpg",
    title: "Georgian vegetable soup",
    weight: "430 g",
    description:
      "Key ingredients: tomatoes, potatoes, bell peppers, carrots, onions, and garlic.",
    price: "5 $",
    toppings: [
      {
        name: "Lemon wedges",
        price: 1,
      },
      {
        name: "Cilantro and parsley",
        price: 1,
      },
      {
        name: "Lavash",
        price: 1,
      },
    ]
  },
  {
    id: 87,
    photo: "https://i0.wp.com/www.cookingislifestyle.com/wp-content/uploads/2020/09/Georgian-tomato-soup-with-fried-eggplant.jpg?fit=1181%2C787&ssl=1",
    title: "Georgian tomato soup",
    weight: "430 g",
    description:
      "Key ingredients: tomatoes, potatoes, bell peppers, carrots, onions, and garlic.",
    price: "5 $",
    toppings: [
      {
        name: "Sour cream",
        price: 1,
      },
      {
        name: "Cilantro and parsley",
        price: 1,
      },
      {
        name: "Lavash",
        price: 1,
      },
    ]
  },
  {
    id: 88,
    photo: "https://1000worldrecipes.com/images/recipes/georgian-bean-soup-with-vegetables-and-rice/bfc3e1c4.jpg",
    title: "Georgian bean soup",
    weight: "350 g",
    description:
      "Key ingredients: tomatoes, potatoes, bell peppers, carrots, onions, and garlic.",
    price: "5 $",
    toppings: [
      {
        name: "Sour cream",
        price: 1,
      },
      {
        name: "Cilantro and parsley",
        price: 1,
      },
      {
        name: "Lavash",
        price: 1,
      },
    ]
  },
  {
    id: 89,
    photo: "https://cdn.vox-cdn.com/thumbor/6u4rosQMWdtyOh9oCmH4FW1MUig=/1400x1400/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/22101798/crab_khachapuri.jpg",
    title: "Crab Dip Khachapuri",
    weight: "350 g",
    description:
      "Crab Dip Khachapuri is a dish that combines elements of Georgian and seafood cuisine.",
    price: "5 $",
    toppings: [
      {
        name: "Chili flakes or hot sauce",
        price: 1,
      },
      {
        name: "Lemon wedges",
        price: 1,
      },
      {
        name: "Fresh herbs",
        price: 1,
      },
    ]
  },
  {
    id: 90,
    photo: "https://i.cbc.ca/1.6614053.1665595813!/fileImage/httpImage/swiss-chard-khachapuri.jpg",
    title: "Swiss Chard Khachapuri",
    weight: "450 g",
    description:
      "Unique variation of the traditional Khachapuri, incorporating Swiss chard.",
    price: "5 $",
    toppings: [
      {
        name: "Chili flakes or hot sauce",
        price: 1,
      },
      {
        name: "Sliced tomatoes",
        price: 1,
      },
      {
        name: "Fresh herbs",
        price: 1,
      },
    ]
  },
  {
    id: 91,
    photo: "https://foodhouse.md/files/styles/main_image/public/product/ojakhuri_cu_carne_de_porc_0.jpg",
    title: "Veal Ojakhuri",
    weight: "350 g",
    description:
      "Veal Ojakhuri is a traditional Georgian dish known for its rich and hearty flavors.",
    price: "5 $",
    toppings: [
      {
        name: "Chili flakes or hot sauce",
        price: 1,
      },
      {
        name: "Garlic bread",
        price: 1,
      },
      {
        name: "Tkemali sauce",
        price: 1,
      },
    ]
  },
  {
    id: 92,
    photo: "https://milaclub.com/uploads/2018/11/milaclub-armjanskaja-dolma.jpg",
    title: "Dolma",
    weight: "350 g",
    description:
      "Dolma is a dish known for its flavorful and savory stuffed grape leaves.",
    price: "5 $",
    toppings: [
      {
        name: "Sour cream",
        price: 1,
      },
      {
        name: "Garlic bread",
        price: 1,
      },
      {
        name: "Tkemali sauce",
        price: 1,
      },
    ]
  },
  {
    id: 93,
    photo: "https://darragoldstein.com/wp-content/uploads/2018/01/Chicken-with-Herbs-Chakhokhbili-1.jpg",
    title: "Chikhohbili",
    weight: "430 g",
    description:
      "Chikhohbili is a dish known for its robust taste and rich combination of ingredients.",
    price: "5 $",
    toppings: [
      {
        name: "Sour cream",
        price: 1,
      },
      {
        name: "Garlic bread",
        price: 1,
      },
      {
        name: "Tkemali sauce",
        price: 1,
      },
    ]
  },
  {
    id: 94,
    photo: "https://thumbs.dreamstime.com/b/lobio-pita-bread-pickles-vegetarian-lobio-pita-bread-pickles-vegetarian-food-diet-herbs-georgian-cuisine-173449205.jpg",
    title: "Imeretian Lobio",
    weight: "430 g",
    description:
      "Imeretian Lobio is a dish celebrated for its rich flavors and hearty qualities.",
    price: "5 $",
    toppings: [
      {
        name: "Vegetable salad",
        price: 1,
      },
      {
        name: "Garlic bread",
        price: 1,
      },
      {
        name: "Cilantro and parsley",
        price: 1,
      },
    ]
  },
  {
    id: 95,
    photo: "https://img.myloview.com/posters/fried-salmon-steak-with-potatoes-and-vegetables-on-wooden-table-700-196253736.jpg",
    title: "Salmon steak",
    weight: "350 g",
    description:
      "A salmon steak is a thick, cross-cut slice of salmon, typically from the center of the fish.",
    price: "5 $",
    toppings: [
      {
        name: "Grilled vegetables",
        price: 1,
      },
      {
        name: "Sourdough bread",
        price: 1,
      },
      {
        name: "Rice",
        price: 1,
      },
    ]
  },
  {
    id: 96,
    photo: "https://alchetron.com/cdn/khachapuri-fa149598-c204-459e-a473-fb4fa06ad8d-resize-750.jpg",
    title: "Khachapuri - Alchetron",
    weight: "350 g",
    description:
      "Boat-shaped khachapuri with a filling of melted sulguni cheese, eggs, and butter.",
    price: "5 $",
    toppings: [
      {
        name: "Vegetables fresh herbs",
        price: 1,
      },
      {
        name: "Tkemali sauce",
        price: 1,
      },
      {
        name: "Sour cream",
        price: 1,
      },
    ]
  },
];

export default Populars;
