const Populars = [
  {
    id: 25,
    photo: "https://bosskitchen.com/wp-content/uploads/2020/12/Screenshot_1-55.jpg",
    title: "Adjarian khachapuri",
    weight: "430 g",
    description:
      "Boat-shaped khachapuri with a filling of melted sulguni cheese, eggs, and butter.",
    price: "5 $",
    toppings: [
      {
        name: "Extra adyghe cheese",
        price: 1,
      },
      {
        name: "Extra mozzarella",
        price: 1,
      },
      {
        name: "Extra feta cheese",
        price: 1,
      },
    ],
  },
  {
    id: 26,
    photo: "https://images.bolt.eu/store/2022/2022-07-20/cca08a47-1d78-4d73-aac1-b80b25268e4d.jpeg",
    title: "Traditional Khinkali",
    weight: "350 g",
    description:
      "Traditional Khinkali is a Georgian dish known for its savory filling and unique twisted.",
    price: "6 $",
    toppings: [
      {
        name: "Sour cream",
        price: 1,
      },
      {
        name: "Extra Red pepper",
        price: 1,
      },
      {
        name: "Tomatoes and onions",
        price: 1,
      },
    ],
  },
  {
    id: 27,
    photo: "https://online-logo.s3-ap-southeast-1.amazonaws.com/homewebsite/131076/thumb_2023_08_19_12_46_22_1_Veg_fried_momo.jpg",
    title: "Fried Khinkali",
    weight: "350 g",
    description:
      "Fried Khinkali is a delightful variation of the traditional Georgian dish, Khinkali.",
    price: "6 $",
    toppings: [
      {
        name: "Tkemali sauce",
        price: 1,
      },
      {
        name: "Sour cream",
        price: 1,
      },
      {
        name: "Fresh vegetables",
        price: 1,
      },
    ],
  },
  {
    id: 28,
    photo: "https://betterme.world/articles/wp-content/uploads/2021/06/shutterstock_710495671.jpg",
    title: "Low-calorie lunch",
    weight: "450 g",
    description:
      "It includes lean protein sources like grilled chicken,tofu, plenty of vegetables. ",
    price: "8 $",
    toppings: [
      {
        name: "Water or herbal tea",
        price: 1,
      },
      {
        name: "Nuts or seeds",
        price: 1,
      },
      {
        name: "Extra fruits",
        price: 1,
      },
    ],
  },
  {
    id: 29,
    title: "Tiramisu",
    description: "Italian dessert with mascarpone and coffee",
    price: "8 $",
    weight: "170 g",
    ingredients: ["mascarpone", "coffee", "sponge cake", "cocoa"],
    photo: "https://i.lefood.menu/wp-content/uploads/w_images/2021/09/recept-3730-1-1240x826.jpg",
    toppings: [
      {
        name: "Cup of green tea",
        price:1,
      },
      {
        name: "Cup of black tea",
        price:1,
      },
      {
        name: "Cup of coffee",
        price: 2,
      },
    ],
  },
  {
    id: 30,
    title: "Chocolate Fondant",
    description: "Served hot with ice cream",
    price: "9 $",
    weight: "230 g",
    ingredients: ["chocolate", "butter", "eggs", "sugar", "ice cream"],
    photo: "https://img.freepik.com/premium-img/dessert-traditional-chocolate-fondant-with-ice-cream-chocolate-plate-top-view_187166-27724.jpg?w=2000",
    toppings: [
      {
        name: "Cup of green tea",
        price:1,
      },
      {
        name: "Cup of black tea",
        price:1,
      },
      {
        name: "Cup of coffee",
        price: 2,
      },
    ],
  },
  {
    id: 31,
    title: "Raspberry Cheesecake",
    description: "Creamy cheesecake with fresh raspberries",
    price: "7 $",
    weight: "210 g",
    ingredients: ["Philadelphia cheese", "raspberries", "cookies", "sugar"],
    photo: "https://img.taste.com.au/Sybwlkfo/w720-h480-cfill-q80/taste/2016/11/raspberry-cheesecake-23872-1.jpeg",
    toppings: [
      {
        name: "Cup of green tea",
        price:1,
      },
      {
        name: "Cup of black tea",
        price:1,
      },
      {
        name: "Cup of coffee",
        price: 2,
      },
    ],
  },
  {
    id: 32,
    title: "Fruit Salad",
    description: "Fresh fruits with honey and nuts",
    price: "6 $",
    weight: "220 g",
    ingredients: ["apples", "pears", "oranges", "honey", "nuts"],
    photo: "https://www.californiastrawberries.com/wp-content/uploads/2021/05/Rainbow-Fruit-Salad-1024-500x500.jpg",
    toppings: [
      {
        name: "Cup of green tea",
        price:1,
      },
      {
        name: "Cup of black tea",
        price:1,
      },
      {
        name: "Cup of coffee",
        price: 2,
      },
    ],
  },
  {
    id: 33,
    title: "Croissant",
    description: "Classic French pastry made with butter and flaky layers",
    price: "3 $" ,
    weight: "80 g",
    ingredients: ["flour", "butter", "yeast", "sugar", "eggs"],
    photo: "https://static01.nyt.com/images/2021/04/07/dining/06croissantsrex1/merlin_184841898_ccc8fb62-ee41-44e8-9ddf-b95b198b88db-articleLarge.jpg",
    toppings: [
      {
        name: "Cup of green tea",
        price:1,
      },
      {
        name: "Cup of black tea",
        price:1,
      },
      {
        name: "Cup of coffee",
        price: 2,
      },
    ] 
  },
  {
    id: 34,
    title: "Danish Pastry",
    description: "Flaky pastry filled with sweet fillings like fruit or cream cheese",
    price: "4 $" ,
    weight: "100 g",
    ingredients: ["flour", "butter", "sugar", "eggs", "fruit jam"],
    photo: "https://nordicfoodliving.com/wp-content/uploads/2017/11/38395858281_8cba7f3994_b-1.jpg",
    toppings: [
      {
        name: "Cup of green tea",
        price:1,
      },
      {
        name: "Cup of black tea",
        price:1,
      },
      {
        name: "Cup of coffee",
        price: 2,
      },
    ] 
  },
  {
    id: 35,
    title: "Eclair",
    description: "Long, thin pastry filled with cream and topped with icing",
    price: "5 $" ,
    weight: "90 g",
    ingredients: ["choux pastry", "cream", "chocolate icing"],
    photo: "https://www.seriouseats.com/thmb/f3kx43qc--aS4-okr8VNpnSAZZQ=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/__opt__aboutcom__coeus__resources__content_migration__serious_eats__seriouseats.com__2020__12__20201210-choux-eclairs-vicky-wasik-16-acf615b81c2f4217857bbf80d60c28c1.jpg",
    toppings: [
      {
        name: "Cup of green tea",
        price:1,
      },
      {
        name: "Cup of black tea",
        price:1,
      },
      {
        name: "Cup of coffee",
        price: 2,
      },
    ] 
  },
  {
    id: 36,
    title: "Palmier",
    description: "Sweet, crispy pastry shaped like a palm leaf",
    price: "3 $" ,
    weight: "60 g",
    ingredients: ["puff pastry", "sugar"],
    photo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQD9IkAVe_fckMbQq833vWeFy9cxRcUs8JImA&usqp=CAU",
    toppings: [
      {
        name: "Cup of green tea",
        price:1,
      },
      {
        name: "Cup of black tea",
        price:1,
      },
      {
        name: "Cup of coffee",
        price: 2,
      },
    ] 
  },
];

export default Populars;
