import React from 'react';
import PopularSnacks from '../components/Popular_snacks';

const Snacks = () => {
    return (
        <div>
            <main>
            <PopularSnacks/>
            </main> 
        </div>
    );
}

export default Snacks;
