const Tiramisu = [
  {
    id: 199,
    title: "Tiramisu",
    description: "Italian dessert with mascarpone and coffee",
    price: "8 $",
    weight: "170 g",
    ingredients: ["mascarpone", "coffee", "sponge cake", "cocoa"],
    photo:
      "https://i.lefood.menu/wp-content/uploads/w_images/2021/09/recept-3730-1-1240x826.jpg",
    toppings: [
      {
        name: "Cup of green tea",
        price: 1,
      },
      {
        name: "Cup of black tea",
        price: 1,
      },
      {
        name: "Cup of coffee",
        price: 2,
      },
    ],
  },
];
export default Tiramisu;
