import React, { useState } from 'react';
import s from "../styles/soups.module.css";
import cart_img from "../assets/img/cart_img.png";
import KhinkaliPop from '../utils/khinkali';
import { Link } from "react-router-dom";
import { useCart } from "../utils/context";

const PopularKhinkali = () => {

  // use context
const {addToCart} = useCart();

const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

    return (
        <div>
          <div className={s.popular}>
            <div className={s.popular__container}>
            <div className={s.main_box}>
            <div className={s.popular__title}><span>Khinkali</span></div>
          <form className={s.input_container}>
            <input className={s.search_input}
              value={searchText}
              onChange={handleSearch}
              type="text"
              placeholder="search"
            />
          </form>
          </div>
              <div className={s.popular__cards}>
                {KhinkaliPop.filter((item) =>
              item.title
                .toLocaleLowerCase()
                .includes(searchText.toLocaleLowerCase())
            ).map((item) => (
                  <div className={s.card}>
                  <Link to={`/khinkali/${item.id}`} className={s.card} key={item.id}>
                    <div className={s.card__img}>
                      <img className={s.card__image} src={item.photo} alt="" />
                    </div>
                    </Link>
                    <div className={s.card__content}>
                      <div className={s.card__title}>{item.title}</div>
                      <div className={s.card__weight}>
                        <p>{item.weight}</p>
                      </div>
                      <div className={s.card__description}>
                        {item.description}
                      </div>
                      <div className={s.card__buy}>
                        <div className={s.card__price}>{item.price}</div>
                        <img src={cart_img} alt="cart_img"
                         // add to cart
                    onClick={() =>addToCart(item)} />
                      </div>
                    </div>
                    </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
}

export default PopularKhinkali;
