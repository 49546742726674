import { createContext, useContext, useState } from "react";

const CartContext = createContext();

export const useCart = () => {
  return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenLogin, setIsModalOpenLogin] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };
  const openModalLogin = () => {
    setIsModalOpenLogin(true);
  };

  // const addToCart = (item) => {
  // setCartItems([...cartItems, {...item}]);
  // console.log(item);
  // };

  const addToCart = (item) => {
    const existingItem = cartItems.find((cartItem) => cartItem.id === item.id);

    if (existingItem) {
      // Якщо товар вже є в корзині, збільшуємо кількість
      existingItem.count += 1;
      setCartItems([...cartItems]);
    } else {
      // Якщо товару немає в корзині, додаємо його
      setCartItems([...cartItems, { ...item, count: 1 }]);
    }
  };
  // +
  const increaseCount = (itemId) => {
    const updatedCart = cartItems.map((item) => {
      if (item.id === itemId) {
        return { ...item, count: item.count + 1 };
      }
      return item;
    });
    setCartItems(updatedCart);
  };
  // -
  const reduceCount = (itemId) => {
    const updatedCart = cartItems.map((item) => {
      if (item.id === itemId) {
        // Перевірка, щоб не допустити від'ємні значення
        const newCount = item.count - 1 >= 1 ? item.count - 1 : 1;
        return { ...item, count: newCount };
      }
      return item;
    });
    setCartItems(updatedCart);
  };

  const removeFromCart = (itemId) => {
    const updatedCart = cartItems.filter((item) => item.id !== itemId);
    setCartItems(updatedCart);
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        isModalOpen,
        openModal,
        setIsModalOpen,
        isModalOpenLogin,
        setIsModalOpenLogin,
        openModalLogin,
        removeFromCart,
        increaseCount,
        reduceCount,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};