import React, { useState } from "react";
import s from "../styles/popular_desserts.module.css";
import cart_img from "../assets/img/cart_img.png";
import desserts from '../utils/desserts';
import { Link } from "react-router-dom";
import { useCart } from "../utils/context";

const PopularDesserts = () => {

  // use context
const {addToCart} = useCart();

const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  return (
    <div>
      <div className={s.popular}>
        <div className={s.popular__container}>
        <div className={s.main_box}>
        <div className={s.popular__title}><span>Desserts</span></div>
          <form className={s.input_container}>
            <input className={s.search_input}
              value={searchText}
              onChange={handleSearch}
              type="text"
              placeholder="search"
            />
          </form>
          </div>
          <div className={s.popular__cards}>
            {desserts.filter((dessert) =>
              dessert.title
                .toLocaleLowerCase()
                .includes(searchText.toLocaleLowerCase())
            ).map((dessert) => (
              <div className={s.card}>
              <Link to={`/desserts/${dessert.id}`} className={s.card}
               key={dessert.id}>
                <div className={s.card__img}>
                  <img className={s.card__image} src={dessert.photo} alt="" />
                </div>
                </Link>
                <div className={s.card__content}>
                  <div className={s.card__title}>{dessert.title}</div>
                  <div className={s.card__weight}>
                    <p>{dessert.weight}</p>
                  </div>
                  <div className={s.card__description}>
                    {dessert.description}
                  </div>
                  <div className={s.card__buy}>
                    <div className={s.card__price}>{dessert.price}</div>
                    <img src={cart_img} alt="cart_img"
                    // add to cart
                    onClick={() =>addToCart(dessert)}
                     />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopularDesserts;

// import React, { useEffect, useState } from "react";
// import s from "../styles/popular_desserts.module.css";
// import cart_img from "../assets/img/cart_img.png";
// import fetchData from "../utils/get_desserts_data";
// import { Link } from "react-router-dom";
// import { useCart } from "../utils/context";

// const PopularDesserts = () => {

//   // use context
// const {addToCart} = useCart();

// const [searchText, setSearchText] = useState("");

//   const handleSearch = (e) => {
//     setSearchText(e.target.value);
//   };

//   const [dessert, setDessert] = useState([]);
//   useEffect(() => {
//     const responseData = async () => {
//       try {
//         const response = await fetchData();
//         setDessert(response);
//       } catch (error) {
//         console.error(error);
//       }
//     };
//     responseData();
//   }, []);
//   return <div>
//         <div className={s.popular}>
//           <div className={s.popular__container}>
//           <div className={s.main_box}>
//           <div className={s.popular__title}><span>Desserts</span></div>
//           <form className={s.input_container}>
//             <input className={s.search_input}
//               value={searchText}
//               onChange={handleSearch}
//               type="text"
//               placeholder="search"
//             />
//           </form>
//           </div>
//              <div className={s.popular__cards}>
//               {dessert.filter((item) =>
//               item.title
//                 .toLocaleLowerCase()
//                 .includes(searchText.toLocaleLowerCase())
//             ).map((item) => (
//               <div className={s.card}>
//                 <Link to={`/desserts/${item.id}`} className={s.card} key={item.id}>
//                   <div className={s.card__img}>
//                     <img className={s.card__image} src={item.photo} alt="" />
//                   </div>
//                   </Link>
//                   <div className={s.card__content}>
//                     <div className={s.card__title}>{item.title}</div>
//                     <div className={s.card__weight}>
//                       <p>{item.weight}</p>
//                     </div>
//                     <div className={s.card__description}>
//                       {item.description}
//                     </div>
//                     <div className={s.card__buy}>
//                       <div className={s.card__price}>{item.price}</div>
//                       <img src={cart_img} alt="cart_img"  
//                       // add to cart
//                       onClick={() =>addToCart(item)}
//                       />
//                     </div>
//                   </div>
//                   </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>;
// };

// export default PopularDesserts;
