import React, { useState } from "react";
import s from "../styles/orderPlacement.module.css";
import ContactdetailsForm from "../components/ContactdetailsForm";
import PaymentDetailsForm from "../components/PaymentDetailsForm";
import greenMark from "../assets/img/greenMark.png";

const PaymentPage = () => {
  const [contactDetailsChecked, setContactDetailsChecked] = useState(false);
  const [paymentDetailsChecked, setPaymentDetailsChecked] = useState(false);

  const toggleContactDetails = () => {
    setContactDetailsChecked(!contactDetailsChecked);
  };

  const togglePaymentDetails = () => {
    setPaymentDetailsChecked(!paymentDetailsChecked);
  };

  return (
    <div>
      <main>
       <h2 className={s.OrderPlacementTitle}>Payment Details</h2>
        <h3>
          01. Contact details {contactDetailsChecked && <img src={greenMark} alt="" />}
        </h3>
        <div className={s.line}></div>
        <ContactdetailsForm toggleContactDetails={toggleContactDetails} />
        <h3>
          03. Payment details {paymentDetailsChecked && <img src={greenMark} alt="" />}
        </h3>
        <div className={s.line}></div>
        <PaymentDetailsForm togglePaymentDetails={togglePaymentDetails} />
      </main>
    </div>
  );
};

export default PaymentPage;
