import React, { useState } from "react";
import s from "../styles/header.module.css";
import logo from "../assets/img/logo.svg";
import { Link } from "react-router-dom";
// import search_icon from "../assets/img/search_icon.svg";
import login_icon from "../assets/img/login_icon.svg";
import cart_icon from "../assets/img/cart_icon.svg";
import whiteArrow from "../assets/img/whiteArrow.png";
import rectangleYellow from "../assets/img/Rectangle 43.png";
import { useCart } from "../utils/context";

const Header = () => {
  const { openModal, openModalLogin, cartItems } = useCart();
  // const location = useLocation()
  // const pathnames = location.pathname.split("/").filter((x) => x)

  const [showBurgerMenu, setShowBurgerMenu] = useState(false);

  const toggleBurgerMenu = () => {
    setShowBurgerMenu(!showBurgerMenu);
  };

  return (
    <div className={s.header}>
      <div className={s.header_container}>
        <img className={s.mainLogo} src={logo} alt="logo" />
        <ul className={s.navLink}>
          <li className={s.fire}>
            <Link to="/" style={{ textDecoration: "none", color: "#fff" }}>
              SPECIALS
            </Link>
          </li>
          <li className={s.hotDishesMenu}>
            <Link
              to="hot-dishes"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              HOT DISHES{" "}
              <img
                style={{ marginLeft: "3px", marginBottom: "1px" }}
                src={whiteArrow}
                alt="whiteArrow"
              />
            </Link>
            <ul className={s.hotDishesSubMenu}>
              <Link to="hot-dishes">
                <li>Hot dishes</li>
              </Link>
              <Link to="soups">
                <li>Soups</li>
              </Link>
              <Link to="khinkali">
                <li>Khinkali</li>
              </Link>
              <li></li>
            </ul>
          </li>
          <li>
            <Link to="snacks" style={{ textDecoration: "none", color: "#fff" }}>
              SNACKS
            </Link>
          </li>
          <li>
            <Link
              to="fresh-pastries"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              FRESH PASTRIES
            </Link>
          </li>
          <li>
            <Link
              to="desserts"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              DESSERTS
            </Link>
          </li>
          <li>
            <Link to="drinks" style={{ textDecoration: "none", color: "#fff" }}>
              DRINKS
            </Link>
          </li>
        </ul>

        <div className={s.iconBurgerBox}>
          <ul className={s.headerIcons}>
            <li>
              <img
                className={s.login_icon}
                src={login_icon}
                alt="login_icon"
                style={{ cursor: "pointer" }}
                onClick={openModalLogin}
              />
            </li>
            <li>
              <img className={s.rectangleYellow} src={rectangleYellow} alt="" />
              <p className={s.countNumber}>{cartItems.length}</p>
              <img
                className={s.cart_icon}
                style={{ cursor: "pointer" }}
                src={cart_icon}
                alt="cart_icon"
                onClick={openModal}
              />
            </li>
          </ul>
          <div className={s.burgerButton} id="menuToggle">
            <input
              className={s.checkBox}
              onClick={toggleBurgerMenu}
              id="checkbox"
              type="checkbox"
            />
            <label className={s.toggle} for="checkbox">
              <div className={`${s.bar} ${s.bar_top}`}></div>
              <div className={`${s.bar} ${s.bar_middle}`}></div>
              <div className={`${s.bar} ${s.bar_bottom}`}></div>
            </label>
          </div>
          {showBurgerMenu && (
            <ul className={s.showMenu}>
              <li className={s.fire}>
                <Link to="/" style={{ textDecoration: "none", color: "#fff" }}>
                  SPECIALS
                </Link>
              </li>
              <li className={s.hotDishesMenu}>
                <Link
                  to="hot-dishes"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  HOT DISHES{" "}
                  <img
                    style={{ marginLeft: "3px", marginBottom: "1px" }}
                    src={whiteArrow}
                    alt="whiteArrow"
                  />
                </Link>
                <ul className={s.hotDishesSubMenu}>
                  <Link to="hot-dishes">
                    <li>Hot dishes</li>
                  </Link>
                  <Link to="soups">
                    <li>Soups</li>
                  </Link>
                  <Link to="khinkali">
                    <li>Khinkali</li>
                  </Link>
                  <li></li>
                </ul>
              </li>
              <li>
                <Link
                  to="snacks"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  SNACKS
                </Link>
              </li>
              <li>
                <Link
                  to="fresh-pastries"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  FRESH PASTRIES
                </Link>
              </li>
              <li>
                <Link
                  to="desserts"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  DESSERTS
                </Link>
              </li>
              <li>
                <Link
                  to="drinks"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  DRINKS
                </Link>
              </li>
            </ul>
          )}
        </div>
      </div>
      {/* <div>
      {pathnames.map((path, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
          return (
            <span key={index}>
              <Link to={routeTo} style={{ textDecoration: "none", color: "#fff" }}>
                {index === pathnames.length - 1 ? path : path + " / "}
              </Link>
            </span>
          );
        })}
      </div> */}
    </div>
  );
};

export default Header;
