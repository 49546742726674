import React from "react";
import s from "../styles/feedback.module.css";
import dessertBottom from "../assets/img/dessertBottom.png";
import dessertRight from "../assets/img/dessertRight.png";

const FeedBack = () => {
  return (
    <div>
      <div>
        <h2 className={s.titleName}>Feedback</h2>
        <form action="">
          <label className={s.textareaLabel} htmlFor="textarea">
            Your question, feedback, or suggestion:
          </label>
          <textarea name="" id=""></textarea>
          <label className={s.emailLabel} htmlFor="email">
            Email to receive a response:
          </label>
          <input type="email" />
          <button className={s.send}>Send</button>
        </form>
      </div>

      <div>
        <div>
        <img className={s.feedbackRight} src={dessertRight} alt="" />
        </div>
      
      <div className={s.bottomImgContainer}>
        <img className={s.feedbackBottom} src={dessertBottom} alt="" />
      </div>
      </div>
      
    </div>
  );
};

export default FeedBack;
