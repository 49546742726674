import React, { useState } from "react";
import s from "../styles/popular_desserts.module.css";
import cart_img from "../assets/img/cart_img.png";
import pastries from './../utils/pastries';
import { Link } from "react-router-dom";
import { useCart } from "../utils/context";

const Fresh_pastries = () => {

  // use context
const {addToCart} = useCart();

const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <div>
      <div className={s.popular}>
        <div className={s.popular__container}>
        <div className={s.main_box}>
        <div className={s.popular__title}><span>Pastries</span></div>
          <form className={s.input_container}>
            <input className={s.search_input}
              value={searchText}
              onChange={handleSearch}
              type="text"
              placeholder="search"
            />
          </form>
          </div>
          <div className={s.popular__cards}>
            {pastries.filter((pastry) =>
              pastry.title
                .toLocaleLowerCase()
                .includes(searchText.toLocaleLowerCase())
            ).map((pastry) => (
              <div className={s.card}>
              <Link to={`/fresh-pastries/${pastry.id}`} className={s.card} key={pastry.id}>
                <div className={s.card__img}>
                  <img className={s.card__image} src={pastry.photo} alt="" />
                </div>
                </Link>
                <div className={s.card__content}>
                  <div className={s.card__title}>{pastry.title}</div>
                  <div className={s.card__weight}>
                    <p>{pastry.weight}</p>
                  </div>
                  <div className={s.card__description}>
                    {pastry.description}
                  </div>
                  <div className={s.card__buy}>
                    <div className={s.card__price}>{pastry.price}</div>
                    <img src={cart_img} alt="cart_img"
                    // add to cart
                    onClick={() =>addToCart(pastry)} />
                  </div>
                </div>
                </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fresh_pastries;