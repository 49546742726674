import React, { useState } from "react";
import s from "../styles/orderPlacement.module.css";
import y from "../styles/delivery.module.css";
import ContactdetailsForm from "./ContactdetailsForm";
import ShippingDetailsForm2 from "./ShippingDetailsForm2";
import ShippingDetailsForm1 from "./ShippingDetailsForm1";
import PaymentDetailsForm from "./PaymentDetailsForm";
import { useCart } from "../utils/context";
import greenMark from '../assets/img/greenMark.png';

const OrderPlacement = () => {
  const { cartItems } = useCart();

  const [selectedDelivery, setSelectedDelivery] = useState("contactless");
  const [contactDetailsChecked, setContactDetailsChecked] = useState(false);
  const [shippingDetailsChecked, setShippingDetailsChecked] = useState(false);
  const [paymentDetailsChecked, setPaymentDetailsChecked] = useState(false);

  const handleDelivery = (deliveryOption) => {
    setSelectedDelivery(deliveryOption);
  };

  const toggleContactDetails = () => {
    setContactDetailsChecked(!contactDetailsChecked);
  };

  const toggleShippingDetails = () => {
    setShippingDetailsChecked(!shippingDetailsChecked)
  }

  const togglePaymentDetails = () => {
    setPaymentDetailsChecked(!paymentDetailsChecked)
  }

  // Calculate the total sum of ordered items
  const totalSum = cartItems.reduce(
    (sum, item) =>
      sum + (item.count > 0 ? parseFloat(item.price) * item.count : 0),
    0
  );

  return (
    <div>
      <h2 className={s.OrderPlacementTitle}>Order placement</h2>
      <div className={s.order_wrapper}
      >
        <div>
          
          <h3>
            01. Contact details{" "}
            {contactDetailsChecked && (
              <img src={greenMark}/>
            )}
          </h3>
          <div className={s.line}></div>
          <ContactdetailsForm toggleContactDetails={toggleContactDetails}/>
          <h3>02. Shipping details{" "}
            {shippingDetailsChecked && (
              <img src={greenMark}/>
            )}</h3>
          <div className={s.line}></div>
          <div className={y.deliveryOption_block}
          >
            <button
              style={{ background: "none", cursor: "pointer" }}
              onClick={() => handleDelivery("contactless")}
              className={
                selectedDelivery === "contactless"
                  ? `${y.deliveryOption} ${y.selected}`
                  : y.deliveryOption
              }
            >
              <div style={{ display: "flex" }}>
                <span className={y.noContactDelivery}>Contactless delivery</span>
                <span className={y.noContactDeliveryPrice}>+0 $</span>
              </div>
              <p className={y.textDelivery}>
                Delivery is available daily from 12:00 PM to 12:00 AM, with a time
                window of 1 to 1.5 hours.
              </p>
            </button>
            <button
              style={{ background: "none", cursor: "pointer" }}
              onClick={() => handleDelivery("selfpickup")}
              className={
                selectedDelivery === "selfpickup"
                  ? `${y.deliveryOption} ${y.selected}`
                  : y.deliveryOption
              }
            >
              <div style={{ display: "flex" }}>
                <span className={y.noContactDelivery}>Self-pickup</span>
                <span className={y.noContactDeliveryPrice2}>+0 $</span>
              </div>
              <p className={y.textDelivery}>
                Available from 12:00 PM to 12:00 AM at the address: 77, Shevchenko
                str., Kharkiv.
              </p>
            </button>
          </div>
          {selectedDelivery === "contactless" ? (
            <ShippingDetailsForm1 toggleShippingDetails={toggleShippingDetails}/>
          ) : (
            <ShippingDetailsForm2 />
          )}

          <h3>03. Payment details{" "}
            {paymentDetailsChecked && (
              <img src={greenMark}/>
            )}</h3>
          <div className={s.line}></div>
          <PaymentDetailsForm togglePaymentDetails={togglePaymentDetails}/>
        </div>

        <div className={s.cartOrderInfo}>
          {cartItems.map((item) => (
            <table>
              <tr key={item.id}>
                <td className={s.image}>
                  <img src={item.photo} alt="" />
                </td>
                <td style={{ textAlign: "left" }} className={s.name}>
                  {item.title}
                </td>
                <td className={s.count_box}>
                  <span className={s.count}>
                    {item.count > 1 ? `${item.count} pcs` : `${item.count} pc`}
                  </span>
                </td>
                <td className={s.total_price}>
                  {item.count > 0 ? parseFloat(item.price).toFixed() * item.count : 0} $
                </td>
              </tr>
            </table>
          ))}

          <div className={s.totalSum}>
            {" "}
            <span>Total price:</span> {totalSum.toFixed()} $
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderPlacement;
