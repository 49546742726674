import React, { useState } from "react";
import Desserts from '../../utils/desserts';
import { useParams } from 'react-router-dom';
import s from "../../styles/itemDescription.module.css";
import minus from "../../assets/img/minus.png";
import plus from "../../assets/img/plus.png";
import cart_img from "../../assets/img/cart_img.png";
import rectangle from "../../assets/img/Rectangle 44.png";
import { useCart } from "../../utils/context";
import OrderedOften from "../../components/OrderedOften";


const DessertsDescription = () => {

  // use context
const {addToCart} = useCart();

    const { itemId } = useParams();
    const selectedItem = Desserts.find(
      (dessert) => dessert.id === parseInt(itemId, 10)
    );
  
    const initializeToppingTotal = () => {
      return selectedItem.toppings.map(() => 0);
    };
  
    const [toppingTotal, setToppingTotal] = useState(initializeToppingTotal);
  
    const increase = (index) => {
      const newQuantities = [...toppingTotal];
      newQuantities[index] += 1;
      setToppingTotal(newQuantities);
    };
  
    const decrease = (index) => {
      if (toppingTotal[index] > 0) {
        const newQuantities = [...toppingTotal];
        newQuantities[index] -= 1;
        setToppingTotal(newQuantities);
      }
    };
  
    const calculateTotal = () => {
      let total = parseFloat(selectedItem.price);
      selectedItem.toppings.forEach((topping, index) => {
        total += topping.price * toppingTotal[index];
      });
      return total.toFixed(2);
    };
  
    if (!selectedItem) {
      return <div>Item not found</div>;
    }
  
    return (
      <div>
        <h2 className={s.productName}>{selectedItem.title}</h2>
        <div className={s.mainWrapper}>
          <img className={s.productPhoto} src={selectedItem.photo} alt="" />
          <div className={s.rightBlockModifications}>
            <p className={s.addUpToYou}>Modify according to your taste:</p>
            <div style={{ display: "flex" }}>
              <div>
                {selectedItem.toppings.map((topping, index) => (
                  <div key={index}>
                    <div className={s.mainRightBox} style={{ display: "flex" }}>
                      <div className={s.modifyProductName}>
                        <div>{topping.name}</div>
                      </div>
                      <div>
                        <div className={s.productQuantity}>
                          <img className={s.rectangle} src={rectangle} alt="" />
                          <button
                            style={{ backgroundColor: "black", border: "none" }}
                            onClick={() => decrease(index)}
                          >
                            <img className={s.minus} src={minus} alt="minus" />
                          </button>
                          {toppingTotal[index]}
                          <button
                            style={{ backgroundColor: "black", border: "none" }}
                            onClick={() => increase(index)}
                          >
                            <img className={s.plus} src={plus} alt="plus" />
                          </button>
                        </div>
                      </div>
  
                      <div className={s.addedPrice}>{topping.price} $</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
  
            <div className={s.totalPriceInfo}>
              <p className={s.totalPrice}>
                Total Price :{" "}
                <span className={s.totalPriceMain}>{calculateTotal()}</span> $
              </p>
              <img className={s.totalPriceImg}
                src={cart_img}
                alt="cart_img"
                // add to cart
              onClick={() =>addToCart(selectedItem)}
              />
            </div>
  
            <div>
              <p className={s.productDescriptionTop}>Description:</p>
              <p className={s.productDescription}>{selectedItem.description}</p>
            </div>
          </div>
        </div>
        <div>
      <OrderedOften/>
      </div>
      </div>
    );
}

export default DessertsDescription;
