import React from 'react';
import HotDishes from '../components/Hot_dishes';


const HotDishesPage = () => {
    return (
        <div>
            <main>
            <HotDishes/>
            </main>
        </div>
    );
}

export default HotDishesPage;
