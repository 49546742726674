import React, { useState } from "react";
import s from "../styles/popular_desserts.module.css";
import cart_img from "../assets/img/cart_img.png";
import Populars from "../utils/hot_dishes";
import { Link } from "react-router-dom";
import { useCart } from "../utils/context";

const HotDishes = () => {
  // use context
  const { addToCart } = useCart();

  const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <div>
      <div className={s.popular}>
        <div className={s.popular__container}>
          <div className={s.main_box}>
          <div className={s.popular__title}><span>Hot dishes</span></div>
          <form className={s.input_container}>
            <input className={s.search_input}
              value={searchText}
              onChange={handleSearch}
              type="text"
              placeholder="search"
            />
          </form>
          </div>
          <div className={s.popular__cards}>
            {Populars.filter((popular) =>
              popular.title
                .toLocaleLowerCase()
                .includes(searchText.toLocaleLowerCase())
            ).map((popular) => (
              <div className={s.card}>
                <Link
                  to={`/hot-dishes/${popular.id}`}
                  className={s.card}
                  key={popular.id}
                >
                  <div className={s.card__img}>
                    <img className={s.card__image} src={popular.photo} alt="" />
                  </div>
                </Link>
                <div className={s.card__content}>
                  <div className={s.card__title}>{popular.title}</div>
                  <div className={s.card__weight}>
                    <p>{popular.weight}</p>
                  </div>
                  <div className={s.card__description}>
                    {popular.description}
                  </div>
                  <div className={s.card__buy}>
                    <div className={s.card__price}>{popular.price}</div>
                    <img
                      src={cart_img}
                      alt="cart_img"
                      // add to cart
                      onClick={() => addToCart(popular)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotDishes;
